@import '../assets//styles/_variables.sass';


.arrStructure {
  width: 100%;
  padding: 12px 0px;
  border-bottom: 1px solid $borderBrandSecondary;

  &:last-child {
    border-color: transparent
  }
}

.arrStructure_wrap {
  width: calc(100% + 24px);
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0px;
  border-radius: 16px;
  margin-left: -12px;
  padding-left: 12px;

  &:hover {
    background: $surfaceSecondary;
  }
}

.arrStructure_wrap_right {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.arrStructure_wrap_rightMain {
  width: 50%;
  display: flex;
  justify-content: right;
  align-items: center;
}

.arrStructure_wrap_svg_open {
  margin-right: 25px;

  svg {
    path {
      fill: $iconPrimary
    }
  }
}

.arrStructure_wrap_svg_delete {}

.dnd_elem {
  margin-right: 23px;
}

.icon_elem {
  display: flex;
  justify-content: center;
  align-items: center;

  min-width: 48px;
  height: 48px;

  background: $surfaceTertiary;
  border-radius: 12px;
  margin-right: 16px;

  svg {
    width: 24px;
    height: 24px;

    path {
      fill: $iconBrand;
    }
  }
}

.moduleTitle {
  font-style: normal;
  font-size: 2rem;
  line-height: 24px;
  color: $textPrimary;
  margin-bottom: 4px;
}

.moduleSubTitle {
  font-style: normal;
  font-size: 1.4rem;
  line-height: 16px;
  color: $textTertiary;

  display: flex;
  justify-content: left;
  align-items: center;

  svg {
    margin-right: 4px;
    fill: $iconBrand;
  }
}



.course_status_in_progress {
  background: linear-gradient(0deg, rgba(255, 187, 51, 0.12), rgba(255, 187, 51, 0.12)), $surfacePrimary;
  border-radius: 8px;
  font-style: normal;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 20px;
  color: #FFBB33;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 2px;
  padding-bottom: 2px;
}

.course_status_completed {
  background: linear-gradient(0deg, rgba(0, 204, 102, 0.12), rgba(0, 204, 102, 0.12)), $surfacePrimary;
  border-radius: 8px;
  font-style: normal;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 20px;
  color: #00CC66;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 2px;
  padding-bottom: 2px;
}

.course_status_not_started {
  background: $surfaceSecondary;
  border-radius: 8px;
  font-style: normal;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 20px;
  color: $textSecondary;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 2px;
  padding-bottom: 2px;
}

.progress_lessons_text {
  width: 35px;
  margin-right: 20px;
  font-style: normal;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 20px;
  color: $textPrimary;
  margin-left: 8px;
}

.procentOfCompleted {
  display: flex;
  justify-content: right;
  align-items: center;
}

.procentOfCompleted__text {
  margin-left: 8px;
  font-style: normal;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 20px;
  text-align: center;
  color: $textPrimary;
}

@media (max-width: 768px) {

  .arrStructure_wrap {
    width: 100%; 
    margin-left: 0px; 
    padding: 10px 0px 12px;

    &:hover {
      background: unset
    }
  }

  .arrStructure_wrap_svg_open {
    margin-right: 0px;

    svg {
      width: 14px;
    height: 7px;
    }
  }
  .arrStructure_wrap_rightMain{

    width: 18px;

   svg > circle {
      display: none;
  }

}

  .progress_lessons_text {
    display: none;
  }

  .moduleTitle {
    font-size: 1.6rem;
        line-height: 20px;
  }

  .icon_elem {
    width: 40px;
    height: 40px;
    min-width: 40px;
  }

  .moduleSubTitle {
    font-size: 1.2rem;
    line-height: 14px;

    svg path {
      fill: $iconTertiary
    }
  }
}