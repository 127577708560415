@import ../../assets/styles/_variables
@import ../../assets/styles/_styles
@import ../../assets/styles/_mixin

.wrapper
    display: grid
    grid-template-columns: 250px minmax(700px,1fr)
    grid-template-rows: minmax(calc(100vh - 186px),1fr)
    +r(1500)
        grid-template-columns: 210px minmax(600px,1fr)
    +r(1350)
        display: flex
        flex-direction: column
        gap: 24px
.header
    color: $textTertiary
    font-size: 1.6rem
    line-height: 20px

    &>div
        display: flex
        gap: 24px
        margin-bottom: 4px
        flex-wrap: wrap
        +r(768)
            gap: 12px
.content
    padding: 0 120px
    padding-top: 16px
    display: flex
    flex-direction: column
    gap: 24px
    +r(1625)
        padding: 0 50px
        padding-top: 16px
    +r(1400)
        padding: 0 10px
        padding-top: 16px
    +r(1350)
        padding: 0
        display: flex
        flex-direction: column
.title
    font-size: 3.2rem
    line-height: 40px
    color: $textPrimary
.right
    border-right: 1px solid $borderBrandSecondary
    padding-right: 24px
    display: flex
    flex-direction: column
    +r(1350)
        border-right: none
        padding: 0
.sticky
    display: grid
    gap: 24px
    position: sticky
    top: 0
.dropdown
    // // padding: 0 24px
    // border-bottom: 1px solid $borderBrandSecondary
    // &:last-child
    //     border-bottom-color: transparent
    &__label
        font-size: 2rem
        line-height: 24px
        padding: 16px 0
        padding-right: 24px
        color: $textSecondary
        display: flex
        align-items: center
        gap: 4px
        position: relative
        border-bottom: 1px solid $borderTertiary
        &:hover
            cursor: pointer
    &__arrow
        position: absolute
        right: 0
        top: 50%
        transform: translateY(-50%)
        border: none
        &_active
            transform: translateY(-50%) rotate(180deg)
    &__papper
        display: grid
        grid-template-columns: 0fr
        grid-template-rows: 0fr
        transition: all .22s
        padding-bottom: 0

        &_toggle
            grid-template-columns: 1fr
            grid-template-rows: 1fr
            padding-bottom: 24px
            position: relative
            &::after
                content: ""
                display: none
                position: absolute
                bottom: 0
                height: 1px
                background-color: $surfaceTertiary
                width: 100vw
                left: -48px
                +r(920)
                    display: block
                +r(768)
                    left: -16px

        &_wrapper
            min-height: 0
            // min-width: 0
            overflow: hidden
ul.list
    display: grid
    gap: 16px
    padding-top: 16px
    max-height: 270px
    overflow-y: auto
.list__item
    font-size: 1.4rem
    // display: inline
    overflow: hidden
    white-space: nowrap
    text-overflow: ellipsis
    position: relative

    &.list__item_active
        &>a
            color: $textBrand
    &:hover
        &>a
            color: $textPrimary
            cursor: pointer
    &>a
        color: $textSecondary
        text-decoration: none
        &:active
            color: $textBrand
.breadcrumbs
    font-size: 1.2rem
    line-height: 14px
    color: $textPrimary
    display: flex
    align-items: center
    text-decoration: none

    &::before
        content: ""
        display: block
        width: 8px
        height: 8px
        border: 1px solid $borderTertiary
        margin: 0 8px
        border-top: none
        border-right: none
        transform: rotate(45deg)

ul.breadcrumbs__wrapper
    padding: 17px 16px
    display: none
    align-items: center
    position: relative
    +r(768)
        display: flex
    &::after
        content: ""
        display: none
        position: absolute
        bottom: 0
        height: 1px
        background-color: $surfaceTertiary
        width: 100vw
        left: -48px
        +r(920)
            display: block
            +r(768)
                left: -16px
    &>li
        font-size: 1.2rem
        line-height: 14px
        color: $textPrimary
        &:hover
            cursor: pointer

.modal
    &__wrapper
        position: fixed
        top: 0
        left: 0
        bottom: 0
        right: 0
        background-color: $surfaceSecondary
        z-index: 100
        background-color: $surfacePrimary
    &__header
        height: 73px
        border-bottom: 1px solid $borderBrandSecondary

        display: flex
        justify-content: flex-end
        align-items: flex-end
        padding: 0 16px
        padding-bottom: 20px
        &>button
            width: 20px
            height: 20px
            display: inline-flex
            align-items: center
            justify-content: center
            padding: 0
            margin: 0
            border: none
            background: none
            &:hover
                cursor: pointer
ul.modal__content
    padding: 0 16px
    padding-top: 40px
    display: flex
    flex-direction: column
    align-items: center
    gap: 12px
    &>li
        font-size: 1.6rem
        line-height: 20px
        color: $textTertiary
        &>a
            text-decoration: none
            color: $textPrimary
