@import ../../assets/styles/_variables

.iconButton
	background: transparent
	border: none
	padding: 0
	cursor: pointer

	&__text
		display: none

	svg
		width: 20px
		height: 20px

		path 
			fill: $iconPrimary

	&:hover
		svg
			path 
				fill: $iconSecondary

@media (max-width: 768px)
	.iconButton
		display: flex
		align-items: center

		&__text
			display: block