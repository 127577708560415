@import ../assets/styles/_variables

.progress
    width: 100%
    height: 100%
    transform: rotate(124deg)
    &>circle
        stroke-width: 8px
        transform: translate(4px,4px)
    &__wrapper
        position: relative 

        stroke-linecap: round

    &__info
        display: block
        position: absolute
        top: 50%
        left: 50%
        transform: translate(-50%, -50%)

.dot
    position: absolute
    inset: 5px
    z-index: 5

    &::before
        content: '',
        width: 16px
        height: 16px
        position: absolute
        bottom: 2px
        left: 35px
        transform: translate(-50%)
        border-radius: 50%
        background-color: $surfaceBrand

.score
    font-size: 4.8rem
    line-height: 56px
    color: $textSecondary

    &__total
        font-size: 2rem
        line-height: 24px
        color: $textSecondary

.primary
    stroke: $iconBrand
.chart
    stroke: $surfaceTertiary

.wrapper
    width: 100% 
    position: relative
    padding-top: 24px
    font-size: 1.6rem
    color: $textSecondary
.total
    width: 100%
    height: 8px
    background: $tertiaryGreen
    opacity: 0.16
    border-radius: 4px
.passed
    background: $tertiaryRed
    opacity: 0.16
    height: 8px
    position: absolute
    top: 24px
    z-index: 1
    border-radius: 4px 0px 0px 4px

    &__wrapper
        background: $surfacePrimary 
        height: 8px
        position: absolute
        top: 24px
        border-radius: 4px 0px 0px 4px
.score
    position: absolute
    width: 16px
    height: 16px
    background: $tertiaryRed
    border-radius: 50%
    border: 4px solid $borderPrimary
    box-sizing: content-box
    top: 16px
    z-index: 3
    left: -6px
    &__passing
        background: $tertiaryGreen
.text__total
    display: flex
    margin-top: 8px
    justify-content: space-between
.text__score
    position: absolute 
    top: 0px
    width: 24px
    text-align: center
    left: -6px
    margin-left: -2px
.title
    font-size: 1.6rem
    color: $textSecondary
@media (max-width: 768px)
    .progress
        &__wrapper
            margin-bottom: 12px
 