@import "../assets/styles/_variables.sass";
@import "../assets/styles/_mixin.sass";

.cards_wrap {
  display: grid;
  grid-template-columns: 2fr minmax(30%, 1fr);
  column-gap: 24px;
}

.card {
  display: flex;
  padding: 24px;

  background: $surfacePrimary;
  box-shadow: $elevation2;
  border-radius: 24px;
  flex-direction: column;
  gap: 8px;

  &>div {
    width: 100%;
  }

  &__title {
    font-size: 1.6rem;
    line-height: 20px;
    color: $textSecondary;

    @include r(768) {
      font-size: 1.4rem;
      line-height: 18px;
    }
  }

  &__wrapper {
    border-bottom: 1px solid $borderBrandSecondary;
    padding-bottom: 8px;

    &:last-child {
      padding-bottom: 0;
      padding-top: 8px;
      border: none;
    }
  }
}

.card_small {
  height: min-content;
  background: $surfacePrimary;
  box-shadow: $elevation2;
  border-radius: 24px;
  padding: 12px 12px;
  position: sticky;
  top: 0;
}

.input {
  width: 100%;
  padding-right: 24px;
  overflow: hidden;
}

/************************************/
.dragAndDrop {
  /*width: 415px;*/
  position: relative;
  /*margin-right: 24px;*/
}

.dragAndDrop_second {
  margin-top: 24px;
}

.dragAndDrop_text {
  width: 100%;
  font-style: normal;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 20px;

  color: $textSecondary;
}

.dragAndDrop_img {
  position: relative;
  /*width: 415px;
  height: 84px;*/
  margin-top: 16px;
  cursor: pointer;
  border-radius: 12px;
  overflow: hidden;
  margin-bottom: 16px;
}

.dragAndDrop_img svg {
  width: 100%;
  height: 100%;
}

.dragAndDrop_icon {
  width: 415px;
  height: 84px;
}

.borderBottom {
  border-bottom: 1px solid $borderBrandSecondary;
}

.dragAndDrop_img input {
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

/*****************************/
.addCover {
  display: none;
}

.dragAndDrop_img:hover .addCover {
  position: absolute;
  width: 80px;
  height: 80px;
  left: calc(50% - 80px / 2);
  top: calc(50% - 80px / 2);

  background: rgba(35, 34, 38, 0.6);
  border-radius: 24px;
  z-index: 10;

  display: flex;
  justify-content: center;
  align-items: center;
}

.addCover svg {
  width: 29px;
  height: 29px;
}

.moduleUpload {
  margin-top: 12px;
  display: flex;
  justify-content: left;
}

.dragAndDrop_text_info {
  padding-top: 20px;
}

.baseTabLine {
  margin-top: 40px;
  margin-bottom: 16px;
}

.tabLines {
  width: 100%;
  display: flex;
  justify-content: left;
  align-items: center;
  position: relative;
}

/************************/
.tabLine {
  width: 100%;
  display: flex;
  justify-content: left;
  align-items: center;
  position: relative;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 24px;
}

.lineTabBottom {
  padding-top: 8px;
}

.tabLine a {
  text-decoration: none;
}

/****************************/
.lineTitleModule {
  width: 100%;
  padding-left: 24px;
  padding-bottom: 24px;
}

.lineTitleModule_title {
  font-style: normal;
  font-weight: 700;
  font-size: 3.2rem;
  line-height: 40px;

  color: $textPrimary;
  margin-bottom: 4px;
}

.lineTitleModule_wrap {
  width: 100%;
  display: flex;
  justify-content: left;
  align-items: center;
}

.lineTitleModule_wrap svg {
  width: 14px;
  height: 14px;
  display: flex;
  justify-content: left;
  align-items: center;
  margin-right: 5px;
}

.lineTitleModule_margin {
  margin-right: 25px;
}

.lineTitleModule_subtitle {
  font-style: normal;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 20px;
  color: $textTertiary;

  display: flex;
  justify-content: left;
  align-items: center;
}

/**************************/
.wrapFiles {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  
  margin-top: -16px;
}
.wrapFiles_top { 
  border-top: 1px solid $borderBrandSecondary;
  margin-top: 16px
}

.files_wrap {
  width: 50%;
  display: flex;
  justify-content: left;
  align-items: center;
  margin-top: 12px;
  margin-bottom: 12px;
  cursor: pointer;
  overflow: hidden;
}

.files_wrap a {
  text-decoration: none;
}

.files_data_wrap {
  width: calc(100% - 25px);
  height: 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 12px;
  padding-left: 22px;
  padding-right: 18px;
}

.dnd_elem {
  width: 10px;
  height: 16px;
  margin-right: 15px;
}

.files_text_and_icon {
  display: flex;
  justify-content: left;
  align-items: center;
}

.files_text_and_icon div {
  font-style: normal;
  font-weight: 500;
  font-size: 2rem;
  line-height: 24px;
  color: $textPrimary;
  overflow: hidden;
}

.files_text_and_icon svg {
  margin-right: 18px;
}

.video {
  width: 100%;
  margin-bottom: 16px;
  position: relative;
}

.video video {
  width: 100%;
  border-radius: 12px;
}

.deleteAvatar {
  display: none;
}

.video video:hover+.deleteAvatar {
  position: absolute;
  top: 0px;
  right: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background: rgba(35, 34, 38, 0.6);
  cursor: pointer;
  border-bottom-left-radius: 12px;
  border-top-right-radius: 12px;
  z-index: 10;
}

.deleteAvatar:hover {
  position: absolute;
  top: 0px;
  right: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background: rgba(35, 34, 38, 0.6);
  cursor: pointer;
  border-bottom-left-radius: 12px;
  border-top-right-radius: 12px;
  z-index: 10;
}

/**************************/
.arrStructure {
  width: 100%;
  border-bottom: 1px solid $borderBrandSecondary;
  margin-bottom: 24px;
  padding-bottom: 24px;

  &:last-child {
    border-color: transparent;
    margin-bottom: 0;
  }
}

.arrStructure_wrap {
  width: 100%;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  border-radius: 16px;

  &:hover {
    background: $surfaceSecondary;
  }
}

.lessonWrap .arrStructure_wrap_right:first-child {
  min-width: 0px;
  width: 90%;
  justify-content: start;
}

.arrStructure_wrap_right {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 16px;

  & svg {
    width: 24px;
    height: 24px;
  }
}

.arrStructure_wrap_rightMain {
  display: flex;
  justify-content: right;
  align-items: center;
}

.arrStructure_wrap_svg_open {
  margin-right: 25px;
}

.arrStructure_wrap_svg_delete {}

.dnd_elem {
  margin-right: 23px;
}

.icon_elem {
  display: flex;
  justify-content: center;
  align-items: center;

  min-width: 48px;
  height: 48px;

  background: $surfaceTertiary;
  border-radius: 12px;
  margin-right: 16px;

  svg {
    path {
      fill: $iconBrand;
    }
  }
}

.moduleTitle {
  font-style: normal;
  font-weight: 500;
  font-size: 2rem;
  line-height: 24px;
  color: $textSecondary;
  text-align: left;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 1;

  &.no_active {
    color: $textTertiary;
  }
}

.moduleSubTitle {
  font-style: normal;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 20px;
  color: $textTertiary;

  display: flex;
  justify-content: left;
  align-items: center;

  svg {
    margin-right: 4px;
  }
}

.lessonsButtons {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.lessonButton {
  margin-top: 24px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  background: $surfaceSecondary;
  border-radius: 12px;
  cursor: pointer;
  font-style: normal;
  font-weight: 500;
  font-size: 2rem;
  line-height: 24px;
  color: $textBrand;

  svg {
    width: 21px;
    height: 21px;
    margin-right: 9px;
  }
}

.lessonButtonMargin {
  margin-left: 16px;
  margin-right: 16px;
}

.lwssonWrap {
  border-top: 1px solid $borderBrandSecondary;
  margin-top: 24px;
  padding-top: 14px;
}

.iconLesson {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  margin-right: 16px;
}

.lessonWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 12px;
  border-radius: 16px;
  margin-bottom: 0px;

  &:hover {
    background: $surfaceSecondary;
  }
}

.lessonWrap a {
  text-decoration: none;
}

.lwssonWrap a {
  text-decoration: none;
}

/*************************/

.product__title {
  font-style: normal;
  font-size: 3rem;
  line-height: 36px;
  color: $textPrimary;
  margin-bottom: 4px;
}

.product__active {
  color: $textPrimary;
}

.product__type {
  text-transform: capitalize;
  margin-right: 24px;
}

.product__subtitle {
  display: flex;
  align-items: center;
  font-style: normal;

  font-size: 1.6rem;
  line-height: 20px;
  color: $textTertiary;
}

.product__subtitle svg {
  margin-right: 4px;
}

.line {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 16px;
  margin-top: 24px;
}

.course_status_not_started {
  font-style: normal;
  font-size: 1.6rem;
  line-height: 20px;
  color: $textTertiary;
  white-space: nowrap;
}

.bttonsLine {
  display: flex;
  justify-content: right;
  align-items: center;

  button {
    margin-right: 16px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.container {
  width: 100%;
}

.description {
  font-style: normal;
  font-weight: 500;
  font-size: 2rem;
  line-height: 24px;
  white-space: pre-wrap;
  color: $textPrimary;
  margin-bottom: 16px;
}

.description p {
  font-size: 2rem !important;
}

.no_border {
  border-color: transparent;
}

.tabletMenu {
  display: none;
}

@media (min-width: 1181px) {
  .line {
    flex-direction: row;
  }
}

@media (min-width: 768px) and (max-width: 1180px) {
  .arrStructure_wrap_svg_open {
    margin-right: 12px;
  }

  .arrStructure_wrap_right {
    margin-right: 12px;
  }

  .buttons {
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
  }

  .cards_wrap {
    grid-template-columns: 1fr;
    padding-bottom: 24px;
    margin-bottom: -24px;

    .card_small {
      display: none;
    }
  }

  .arrStructure {
    padding-left: 12px;
  }

  .tabletMenu {
    display: block;

    &>div>div:first-child {
      display: none;
    }

    &>div>div:nth-child(2) {
      display: none;
    }

    &>div>div:last-child {
      display: block;
    }

    &>div>div:last-child>div:first-child {
      display: none;
    }
  }
}

.homework__desc {
  margin-bottom: 24px;
}

.homework__done {
  display: block;
  color: $textPrimary;
  margin-bottom: 24px;
}

.homework__done_text {
  font-size: 2rem;
  margin-bottom: 24px;
}

.homework__done_title {
  font-size: 3rem;
  margin-bottom: 8px;
}

@media (max-width: 768px) {

  .description p {
    font-size: 1.6rem !important;
  }

  .homework__done {
    border-radius: 24px;
    margin: 0px 0px 16px;
    width: 100%;
  }

  .homework__done_text {
    font-size: 1.6rem;
    margin-bottom: 12px;
  }

  .homework__done_title {
    font-size: 2.4rem;
    margin-bottom: 12px;
  }

  .homework__desc {
    margin-bottom: 16px;
  }

  .buttons {
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
  }


  .tabletMenu {
    display: block;

    &>div>div:first-child {
      display: none;
    }

    &>div>div:nth-child(2) {
      display: none;
    }

    &>div>div:last-child {
      display: block;
    }

    &>div>div:last-child>div:first-child {
      display: none;
    }
  }

  .tabletMenu {
    &>div>div:last-child>div:last-child {
      display: block;
    }
  }

  .line {
    flex-direction: column;
    align-items: start;
    margin-bottom: 4px;
  }

  .cards_wrap {
    grid-template-columns: 1fr;
    padding-top: 12px;
  }

  .card {
    border-radius: 0px;
    padding: 20px 16px;
    margin: 0px -16px;
    width: 100vw;
  }

  .homework__done {
    border-radius: 20px;
    margin: 16px 0px 0px;
    width: 100%;
  }

  .wrapFiles {
    flex-direction: column;
    align-items: start;
    max-width: calc(100vw - 32px);
  }

  .arrStructure {
    padding: 0px 16px;
    background: $surfacePrimary;
    margin-bottom: 20px;
    padding-bottom: 20px;
  }

  .bttonsLine {
    width: 100%;

    button {
      width: 50%;
    }

    button:only-child {
      width: 100%;
    }
  }

  .baseTabLine {
    overflow: scroll;
    max-width: calc(100vw);
    margin: 30px -24px 0px;
    padding: 0px 24px;
  }

  .baseTabLine::-webkit-scrollbar {
    display: none;
  }

  .baseTabLine {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .product__title {
    font-size: 2.4rem;
    line-height: 32px;
  }

  .product__subtitle {
    font-size: 1.2rem;
  }

  .moduleTitle {
    font-size: 1.6rem;
  }

  .moduleSubTitle {
    font-size: 1.2rem;
  }

  .arrStructure_wrap_right svg {
    width: 16px;
    height: 16px;
  }

  .iconLesson {
    margin-right: 12px;
  }

  .iconLesson svg {
    width: 20px;
    height: 20px;
  }

  .arrStructure_wrap_right {
    & .moduleTitle {
      font-size: 1.4rem;
    }
  }

  .arrStructure_wrap {
    padding: 0px;

    &:hover {
      background: unset;
    }
  }

  .icon_elem {
    width: 40px;
    height: 40px;
    min-width: 40px;
  }

  .course_status_not_started {
    font-size: 1.2rem;
  }

  .arrStructure_wrap_svg_open {
    margin-right: 0px
  }

  .arrStructure_wrap_right {
    margin-right: 0px
  }

  .lessonWrap {
    padding: 0px 0px 12px
  }

  a:last-child .lessonWrap {
    padding-bottom: 0px;
  }

  .lwssonWrap {
    margin-top: 20px;
    padding-top: 20px;
  }
}

div.attachments {
  width: 99%;

  &__wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;
    width: 100%;

    &.attachments__wrapper_task {}

    @include r(768) {
      display: flex;
      flex-direction: column;
    }

  }



  &__title {
    font-size: 1.6rem;
    color: $textPrimary;
    position: relative;

    &::before {
      content: "";
      background-color: $textPrimary;
      height: 1px;
      width: 0%;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      transition: width .22s;
    }

    &:hover {
      &::before {
        width: 100%;
      }
    }
  }

  &__container {
    border-top: 1px solid $borderBrandSecondary;
    padding-top: 16px;
  }
}

.comments {
  &__title {
    font-size: 2rem;
    font-style: normal;
    line-height: 26px;
    color: $textPrimary
  }
}

.card_big {
  display: flex;
  flex-direction: column;
  gap: 24px;
}