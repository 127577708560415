@import ../../assets/styles/_variables

.wrapper
    width: 160px
    height: 160px
    border-radius: 50%
    font-style: normal
    font-weight: 600
    font-size: 6.4rem
    line-height: 56px
    text-align: center
    color: $textBrand
    background-color: $surfaceTertiary
    display: flex
    align-items: center
    justify-content: center
    overflow: hidden
    position: relative
    border: 1px solid $borderBrandSecondary
    &:hover
        cursor: pointer
        .icon__move
            display: block
        .icon__remove
            display: block

    input[type="file"]
        display: none

.avatar
    width: 100%
    height: 100%
    object-fit: cover

.icon
    position: absolute
    display: none
    &__remove
        top: 0

    &__move
        bottom: 0

.active
    border: 1px dashed $borderTertiary
