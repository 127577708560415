@import '../assets/styles/_variables.sass'


.page404
	text-align: center
	padding: 80px 0px 64px
	color: $textSecondary

	&__button
		display: inline-flex
		justify-content: left
		padding: 10px 22px
		color: $textBrand
		border-radius: 12px
		text-transform: capitalize
		border-width: 2px
		border-style: solid
		border-color: $borderBrand
		font-size: 2rem
		line-height: 24px
		text-decoration: none
		font-weight: 600

		&:hover
			color: $buttonBrandHovered
			border-color: $buttonBrandHovered

	h4
		margin: 40px 0px 16px
		font-weight: 700
		font-size: 32px
		line-height: 40px

	p
		font-size: 20px
		line-height: 24px
		margin-bottom: 40px


@media (max-width: 768px)
		.page404
				svg
						width: 100%
						height: auto