@import '../assets/styles/_variables.sass' 


.reviews 
    padding-top: 24px

    &__inner
        display: grid
        grid-template-columns: 4fr 2fr
        column-gap: 24px

    &__title
        font-weight: 700
        font-size: 32px
        line-height: 40px
        margin-bottom: 24px
        color: $textPrimary
        text-align: left

    &__more
        text-align: center
        padding: 24px 0px

        svg
            transform: rotate(90deg)
            margin-right: 13px

            path
                fill: $iconBrand    

    &__moreButton
        align-items: center
        background: $surfaceSecondary
        border: 2px solid $borderBrand
        border-radius: 12px
        color:  $textBrand
        cursor: pointer
        display: inline-flex
        font-size: 2rem
        height: 48px
        margin-right: 16px
        padding: 0 24px
        text-decoration: none

    &__moreButton:hover 
        border: 2px solid $buttonBrandHovered  
        color: $buttonBrandHovered  

        svg
            path
                fill: $buttonBrandHovered    

.reviewsSidebar
    &__openModal
        display: none

    &__header
        display: flex
        justify-content: space-between

    &__title
        display: flex
        justify-content: space-between   

    &__showModal
        display: none

.reviewSend

    &__modal
        max-width: 400px
        padding: 24px
        height: auto !important
        box-shadow: $elevation4
        border-radius: 24px
        background: $surfacePrimary

    &__header
        display: flex
        justify-content: space-between
        padding-bottom: 24px
        border-bottom: 1px solid $borderBrandSecondary
        margin-bottom: 24px

    &__title
        font-size: 20px
        line-height: 24px
        color: $textSecondary

    &__rating
        display: flex
        margin-bottom: 16px

        svg
            path
                fill: $iconBrand

        p
            font-size: 20px
            line-height: 24px
            color: $textPrimary
            margin-right:18px

    &__textarea
        text-align: left
        margin-bottom: 44px

    &__buttons
        display: flex
        justify-content: right

    &__cancelButton
        color: $textBrand
        background: transparent
        margin-right: 24px

    &__rate

        display: flex


    &__rateItem
        cursor: pointer
    &__close
        cursor: pointer

    &__openButton
        margin: auto

 
.reviewsList
    border-radius: 24px
    /* background: $surfacePrimary */
    padding: 24px
    /*box-shadow: $elevation2 */

.reviewItem
    display: flex 

    &__inner
        display: flex
        width: 100%

    &__data
        width: 100%

    &__header
        display: flex
        justify-content: space-between

    &__title
        display: flex
        margin-bottom: 8px

    &__name
        margin-right: 16px
        font-size: 16px
        color: $textSecondary

    &__date
        font-size: 16px
        color: $textTertiary

    &__text
        font-size: 16px
        color: $textPrimary

    &__fullText
        overflow: hidden
        text-overflow: ellipsis
        display: -webkit-box
        -webkit-line-clamp: 3
                line-clamp: 3
        -webkit-box-orient: vertical
        word-break: break-all
    &__more
        margin-top: 8px
        color: $textBrand
        cursor: pointer
        font-size: 1.6rem
    
.reviewItem:not(:last-child)
    border-bottom: 1px solid $borderBrandSecondary
    padding-bottom: 16px
    margin-bottom: 16px



.reviewsSidebar 
    word-break: break-all

    &:last-child 
        border-color: transparent

    &__blockTitle  
        font-style: normal 
        font-size: 1.6rem 
        line-height: 20px 
        color: $textPrimary 
        margin-bottom: 8px 
        font-weight: 600
     
    &__main  
        display: flex 
        justify-content: left 
        align-items: center  
    &__data
        width: 100%

    &__link
        font-size: 2rem
        line-height: 20px
        color: $textBrand 


.review__title  
    font-style: normal 
    font-size: 2rem 
    line-height: 24px 
    color: $textPrimary 
    margin-bottom: 4px 
 

.review__subtitle  
    font-style: normal 
    font-size: 1.6rem 
    line-height: 20px 
    color: $textTertiary 
 

.review__text 
    font-style: normal 
    font-size: 1.6rem 
    line-height: 20px 
    color: $textSecondary 
    margin-bottom: 12px 
    overflow: hidden
    text-overflow: ellipsis
    display: -webkit-box
    -webkit-line-clamp: 3
    line-clamp: 3
    -webkit-box-orient: vertical

.tabLine_name  
    display: flex 
    justify-content: left 
    align-items: center 

    font-style: normal 
    font-weight: 500 
    font-size: 2rem 
    line-height: 24px 
    color: $textPrimary 
 

.tabLine_name_round  
    display: flex 
    flex-direction: row 
    justify-content: center 
    align-items: center 

    width: 56px 
    height: 56px 
    margin-right: 16px 

    background: $surfaceTertiary 
    border-radius: 26px 

    font-style: normal 
    font-weight: 600 
    font-size: 2.4rem 
    line-height: 40px 
    text-align: center 
    color: $textBrand 
 

.tabLine_name_round img  
    border-radius: 26px 
    overflow: hidden 
    width: 100%
    height: 100%
    object-fit: cover

.dot  
    width: 12px 
    height: 12px 
    background-color: $surfaceTertiary 
    border-radius: 4px 
    border: none 
    margin-right: 12px 

    &_current  
        background: $surfaceBrand 
     

    &:last-child  
        margin-right: 0 
     

    &:hover  
        cursor: pointer 
     

    &__group  
        display: flex 
        justify-content: center 
        align-items: center 
        margin-bottom: 24px
     
.reviewRating
    min-width: 80px
    margin-left: 8px

    svg
        width: 16px
        height: 16px


    svg
        path
            fill: $iconBrand


.reviewsStatistic
    border-radius: 24px
    background: $surfacePrimary
    padding: 24px
    box-shadow: $elevation2
    height: fit-content

    svg
        path
            fill: $iconBrand

    h1
        font-weight: 700
        font-size: 32px
        line-height: 40px
        margin-right: 18px
        color: $textPrimary
    h3 
        font-weight: 600
        font-size: 20px
        line-height: 24px
        margin-right: 8px
        color: $textPrimary

    &__header
        display: flex
        justify-content: space-between
        margin-bottom: 16px
        align-items: center

    &__item
        display: flex
        justify-content: space-between
        align-items: center
        margin-bottom: 16px

    &__totalTop
        font-size: 16px
        line-height: 20px
        color: $textTertiary

    &__total
        font-size: 16px
        line-height: 20px
        color: $textTertiary
        width:10%
        margin-left: 8px

    &__percent
        font-weight: 500
        font-size: 20px
        line-height: 24px
        color: $textPrimary
        margin-right: 8px
        width:10%

    &__progress
        margin-right: 8px  
        border-radius: 8px
        background: $surfaceTertiary
        height: 24px
        width: 216px

    &__progressPercent
        border-radius: 8px
        background: $surfaceBrand
        height:100%

    &__rating
        position: relative
    
    &__score
        width:10%
        

    &__scoresFilled
        position: absolute
        top: 0
        overflow: hidden

    &__scoresFilledInner
        width: max-content


.NoItem  
    font-style: normal 
    font-weight: 500 
    font-size: 1.6rem 
    line-height: 20px 
    color: $textTertiary   
    margin-bottom: 8px
 
.noItems
    display: flex
    align-items: center
    justify-content: center
    flex-direction: column
    color: $textSecondary

    h3
        margin: 40px 0px 16px 0px
        font-weight: 700
        font-size: 32px
        line-height: 40px

    p
        font-size: 20px
        line-height: 24px

.reviewItem__textWrapperMobile
    display: none


@media (min-width: 1181px)
    .reviewsStatistic__sendButton
        margin-bottom: 16px

        & > button
            width: 100%
            justify-content: center !important

@media (min-width: 769px) and (max-width: 1180px)
    .reviewsSidebar
        text-align: left

    .reviews 
        &__inner
            display: flex
            flex-direction: column
        &__left
            order: 2 
    .reviewsStatistic
        margin-bottom: 16px
        display: flex
        justify-content: space-between

        &__header
            min-width: 240px
            margin-bottom: 0px

    .reviewsStatistic__items 
        display: none

    .reviewsStatistic__sendButton
        order: 2

@media (max-width: 768px)
    .reviewsStatistic__sendButton
        margin-bottom: 16px

    .reviewItem 
        flex-direction: column
        
        &:not(:last-child)
            padding-bottom: 12px
            margin-bottom: 12px

        &__inner
            margin-bottom: 8px

    .reviewItem__textWrapperMobile
        display: block

    .reviewItem__textWrapper
        display: none

    .reviewsSidebar
        &__openModal
            display: inline
            font-size: 1.6rem
            line-height: 20px
            color: $textBrand
            text-decoration: none

        &__link
            display: none

    .reviewsList
        padding-bottom: 0px
        background: none
    .reviews
        padding-top: 16px

        &__moreButton
            font-size: 1.6rem
            padding: 8px 16px
            height: auto
            margin-right: 0px

            svg
                width: 13px

        &__title
            font-size: 2.4rem
            line-height: 32px
            padding-bottom: 20px
            border-bottom: 1px solid $borderBrandSecondary
 
            border-bottom: 0px
 
        &__more 
            background: $surfaceSecondary
        &__inner
            display: flex
            flex-direction: column
        &__left
            order: 2
    .reviewsStatistic
        order: 1
        border-radius: 0px
        padding: 20px 24px
        border-bottom: 1px solid $borderBrandSecondary
        padding: 20px 0px 8px
        

        border-radius: 24px
        border-bottom: none
        padding: 16px

        h1 
            font-size: 2.4rem
            line-height: 32px
        h3 
            font-size: 1.6rem
            line-height: 20px
        &__percent    
            font-size: 1.6rem
        &__total 
            font-size: 1.2rem
            text-align: right

        &__totalTop
            font-size: 1.2rem

        &__header
            margin-bottom: 12px
 

        &__rating 
            margin-right: auto
            svg 
                width: 20px
                height: 20px
            svg:not(:last-child)
                margin-right: 4px
        &__progress
            height: 20px
        &__item
            margin-bottom: 12px

    .reviewsList
        border-radius: 0px

    .reviewItem
        &__name
            margin-bottom: 4px
            font-size: 1.2rem
            text-align: left
        &__date
            font-size: 1.2rem
        &__fullText
            font-size: 1.4rem
            text-align: left
        &__text
            font-size: 1.4rem
        &__title
            flex-direction: column
        & .reviewRating
            display: flex
            align-items: center
            justify-content: right

            &__score
                margin-right: 4px
                font-size: 1.2rem
                color: $textBrand
                padding-bottom: 2px
        &__more
            margin-top: 8px
            color: $textBrand
            cursor: pointer
            font-size: 1.4rem
    .reviewSend
        &__textarea
            margin-bottom: 16px
        &__modal
            height: 100% !important
            border-radius: 0px
            max-width: none
            height: auto !important
            margin-top: auto
            border-radius: 24px 24px 0px 0px

            & > div 
                height: 100%
        &__header
            padding-bottom: 16px
            margin-bottom: 20px

        &__close
            display: flex
            align-items: center


            svg
                width: 11px
                height: 11px 
                path 
                    fill: $iconPrimary

        &__title
            font-size: 1.6rem

        &__form
            display: flex
            flex-direction: column
            height: 100%

        &__buttons
            margin-top: auto

        &__openButton
            padding: 8px 16px
            width: 100%
            font-size: 1.6rem

        &__rating

            p
                font-size: 1.6rem

        &__rateItem:not(:last-child)
            margin-right: 4px

    .modal__inner 
        height: 100%

    .courseInfo__reviews
        text-align: left

    .reviewsSidebar
        &__blockTitle
            font-size: 1.6rem
            margin-bottom: 8px
        &__main
            align-items: start
            margin-bottom: 8px

        &__link
            font-size: 1.6rem
    .NoItem
        font-size: 1.6rem
    .review
        &__title
            font-size: 1.2rem
            line-height: 14px
        &__text
            font-size: 1.4rem
            text-align: left
    .tabLine_name_round
        width: 40px
        height: 40px
        font-size: 1.4rem
        margin-right: 12px
    .modal__header
        display: flex
        justify-content: space-between
        padding: 16px
        font-size: 1.6rem
        color: $textSecondary
        border-bottom: 1px solid $borderBrandSecondary
        margin-bottom: 20px
        align-items: center
    .modal__cancel
        width: 11px
        height: 11px

        svg 
            path 
                fill: $iconPrimary
    .reviewsSidebar .reviewRating svg:not(:last-child)
        margin-right: 4px       
    .noItems 
        svg 
            width: 260px
            height: 214px
        h3 
            font-size: 2.4rem
            line-height: 32px
        p 
            font-size: 1.6rem
            line-height: 20px