@import ../../assets/styles/_variables

.wrapper
    background: $surfaceSecondary
    display: flex
    flex-direction: column
    min-height: 100vh
    justify-content: center
.footer
    margin-top: auto
.app
    min-height: calc(100vh - 186px)
    margin-bottom: 24px
    display: flex
    justify-content: center
    align-items: center

.content
    background-color: $surfacePrimary
    border-radius: 24px
    box-shadow: $elevation2
    padding: 24px
    max-width: 360px
    max-height: 360px
    width: 100%
    height: 100%
    display: grid
    gap: 16px
    justify-items: center
    color: $textPrimary
    &>svg
        &>path
            fill: $iconBrand
.title
    font-size: 3.2rem
    line-height: 4rem
.description
    font-size: 1.6rem
    line-height: 2rem
    text-align: center
.header
    &__container
        display: flex
        justify-content: space-between
        align-items: center
        height: 80px

    &__center
        display: flex
        justify-content: space-between

    &__logo
        min-width: 165px
        display: flex
        align-items: center
        text-decoration: none

        img
            height: 28px

        p
            margin-left: 10px
            color: $textPrimary
            font-size: 3rem
            letter-spacing: 1px
    .header__logoText
        margin-left: 8px
        color: #000
        font-family: $GilroyMedium
        font-size: 2.8rem
        line-height: 28px

    &__link
        margin-right: 64px
        text-decoration: none
        position: relative
        top: 2px
        font-style: normal
        font-size: 2rem
        line-height: 24px
        display: flex
        align-items: center
        color: $textSecondary
        padding: 26px 0

        &:hover
            cursor: pointer
            color: $textBrand

        &_active
            color: $textBrand
        &_active:after
            content: ""
            display: block
            background: $surfaceBrand
            width: 100%
            position: absolute
            height: 2px
            border-radius: 4px
            bottom: -1px

        &:last-child
            margin-right: 0

    &__right
        display: flex
        align-items: center
        padding-right: 8px

        &_icon
            margin-right: 35px
            display: flex

            button
                cursor: pointer

                svg
                    width: 24px
                    height: 24px
                    path
                        fill: $iconTertiary

            button:hover
                svg
                    path
                        fill: $iconSecondary

            & > div:not(:last-child)
                margin-right: 35px
header.header
    display: block
    background: $surfacePrimary
    border-bottom: 1px solid $borderBrandSecondary
