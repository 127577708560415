@import '../src/components/assets/styles/_reset'
@import '../src/components/assets/styles/_fonts'
@import '../src/components/assets/styles/_mixin'
@import '../src/components/assets/styles/_variables'

html
	font-size: 10px
	overflow-x: hidden

body
	margin: 0
	padding: 0
	-webkit-font-smoothing: antialiased
	-moz-osx-font-smoothing: grayscale
	box-sizing: border-box
#root
	position: relative
	z-index: 1
