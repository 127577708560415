@import ../../assets/styles/_variables

.GilroyBold
    font-family: $GilroyBold
.GilroySemiBold
    font-family: $GilroySemiBold
.GilroyMedium
    font-family: $GilroyMedium
.GilroyLight
    font-family: $GilroyLight
.Gilroy
    font-family: $Gilroy

.UbuntuBold
    font-family: $UbuntuBold
.UbuntuSemiBold
    font-family: $UbuntuSemiBold
.UbuntuMedium
    font-family: $UbuntuMedium
.UbuntuLight
    font-family: $UbuntuLight
.Ubuntu
    font-family: $Ubuntu

.RobotoBold
    font-family: $RobotoBold
.RobotoSemiBold
    font-family: $RobotoSemiBold
.RobotoMedium
    font-family: $RobotoMedium
.RobotoLight
    font-family: $RobotoLight
.Roboto
    font-family: $Roboto

.PoppinsBold
    font-family: $PoppinsBold
.PoppinsSemiBold
    font-family: $PoppinsSemiBold
.PoppinsMedium
    font-family: $PoppinsMedium
.PoppinsLight
    font-family: $PoppinsLight
.Poppins
    font-family: $Poppins
