//Poppins
//700
@font-face
    font-family: 'PoppinsBold'
    // src: url('../fonts/Poppins-Bold.woff') format('woff')
    src: url('../fonts/Poppins-Bold.ttf') format('truetype')
    font-weight: normal
    font-style: normal
    font-display: swap

//500
@font-face
    font-family: 'PoppinsMedium'
    src: url('../fonts/Poppins-Medium.ttf') format('truetype')
    font-weight: normal
    font-style: normal
    font-display: swap

//400
@font-face
    font-family: 'Poppins'
    src: url('../fonts/Poppins-Regular.ttf') format('truetype')
    font-weight: normal
    font-style: normal
    font-display: swap

//600
@font-face
    font-family: 'PoppinsSemiBold'
    src: url('../fonts/Poppins-SemiBold.woff') format('woff')
    src: url('../fonts/Poppins-SemiBold.ttf') format('truetype')
    font-weight: normal
    font-style: normal
    font-display: swap

//300
@font-face
    font-family: 'PoppinsLight'
    src: url('../fonts/Poppins-Light.ttf') format('truetype')
    font-weight: normal
    font-style: normal
    font-display: swap

//Gilroy
//700
@font-face
    font-family: 'GilroyBold'
    src: url('../fonts/GILROY/GilroyNew-SemiBold.otf') format('truetype')
    font-weight: normal
    font-style: normal
    font-display: swap

//500
@font-face
    font-family: 'GilroyMedium'
    src: url('../fonts/GILROY/GilroyNew-Medium.otf') format('truetype')
    font-weight: normal
    font-style: normal
    font-display: swap

//400
@font-face
    font-family: 'Gilroy'
    src: url('../fonts/GILROY/GilroyNew-Regular.otf') format('truetype')
    font-weight: normal
    font-style: normal
    font-display: swap

//600
@font-face
    font-family: 'GilroySemiBold'
    src: url('../fonts/GILROY/GilroyNew-SemiBold.otf') format('truetype')
    font-weight: normal
    font-style: normal
    font-display: swap

//300
@font-face
    font-family: 'GilroyLight'
    src: url('../fonts/GILROY/GilroyNew-Light.otf') format('truetype')
    font-weight: normal
    font-style: normal
    font-display: swap

//Ubuntu
//700
@font-face
    font-family: 'UbuntuBold'
    // src: url('../fonts/Poppins-Bold.woff') format('woff')
    src: url('../fonts/Ubuntu/Ubuntu-Bold.ttf') format('truetype')
    font-weight: normal
    font-style: normal
    font-display: swap

//500
@font-face
    font-family: 'UbuntuMedium'
    src: url('../fonts/Ubuntu/Ubuntu-Medium.ttf') format('truetype')
    font-weight: normal
    font-style: normal
    font-display: swap

//400
@font-face
    font-family: 'Ubuntu'
    src: url('../fonts/Ubuntu/Ubuntu-Regular.ttf') format('truetype')
    font-weight: normal
    font-style: normal
    font-display: swap

//600
@font-face
    font-family: 'UbuntuSemiBold'
    src: url('../fonts/Ubuntu/Ubuntu-Medium.ttf') format('truetype')
    font-weight: 600
    font-style: normal
    font-display: swap

//300
@font-face
    font-family: 'UbuntuLight'
    src: url('../fonts/Ubuntu/Ubuntu-Light.ttf') format('truetype')
    font-weight: normal
    font-style: normal
    font-display: swap

//Roboto
//700
@font-face
    font-family: 'RobotoBold'
    // src: url('../fonts/Poppins-Bold.woff') format('woff')
    src: url('../fonts/Roboto/Roboto-Bold.ttf') format('truetype')
    font-weight: normal
    font-style: normal
    font-display: swap

//500
@font-face
    font-family: 'RobotoMedium'
    src: url('../fonts/Roboto/Roboto-Medium.ttf') format('truetype')
    font-weight: normal
    font-style: normal
    font-display: swap

//400
@font-face
    font-family: 'Roboto'
    src: url('../fonts/Roboto/Roboto-Regular.ttf') format('truetype')
    font-weight: normal
    font-style: normal
    font-display: swap

//600
@font-face
    font-family: 'RobotoSemiBold'
    src: url('../fonts/Roboto/Roboto-Medium.ttf') format('truetype')
    font-weight: 600
    font-style: normal
    font-display: swap

//300
@font-face
    font-family: 'RobotoLight'
    src: url('../fonts/Roboto/Roboto-Light.ttf') format('truetype')
    font-weight: normal
    font-style: normal
    font-display: swap
