@import '../../assets/styles/_variables.sass';

.input {
    width: 100%;

    &_wrap {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 12px 16px;

        background: $surfacePrimary;

        border-radius: 12px;
    }
}

.base {
    background: $surfacePrimary;
    border: 2px solid $borderBrandSecondary;

    .input_elem {
        background: $surfacePrimary;
        border: 0px;
    }
}

.base:focus-within {
    background: $surfacePrimary;
    border: 2px solid $borderBrand;
}

.error {
    background: $surfacePrimary;
    border: 2px solid $tertiaryRed;

    .input_elem {
        background: $surfacePrimary;
        border: 0px;
    }
}

.disabled {
    background: $surfaceSecondary;
    border: 2px solid $borderBrandSecondary;

    .input_elem {
        background: $surfaceSecondary;
        border: 0px;
    }
}

.disabledFilled {
    background: $surfaceSecondary;
    border: 2px solid $borderBrandSecondary;

    .input_elem {
        background: $surfaceSecondary;
        border: 0px;
        color: $textSecondary
    }
}

.readOnly {
    background: $surfaceSecondary;
    border: 2px solid $borderBrandSecondary;

    .input_elem {
        background: $surfaceSecondary;
        border: 0px;
        color: $textPrimary;
    }
}

.label {
    margin-bottom: 4px;
    font-style: normal;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 20px;

    color: $textSecondary;
}

.helper {
    height: 16px;
    margin-top: 4px;
    font-style: normal;
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 16px;

    color: $textSecondary;
}

.error+.helper {
    color: $tertiaryRed;
}

.text {
    font-style: normal;
    font-weight: 500;
    font-size: 2rem;
    line-height: 24px;

    color: $textTertiary;
}

textarea {
    font-style: normal;
    font-weight: 500;
    font-size: 2rem;
    line-height: 24px;

    color: $textPrimary;

    width: 100%;
    background: transparent;
    border: 0px;
    outline: none;
    resize: none;
}

input:active {
    background: transparent;
    border: 0px;
    outline: none;
}

input:focus {
    background: transparent;
    border: 0px;
    outline: none;
}

.iconWrap {
    width: 21px;
    height: 21px;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
        width: 100%;
        height: 100%;
    }
}

.left {
    margin-right: 9px;
}

.right {
    margin-left: 9px;
}

.idle {
    font-style: normal;
    font-weight: 500;
    font-size: 2rem;
    line-height: 24px;

    color: $textTertiary;
}

.marginBottom {
    margin-bottom: 16px;
}

@media (max-width: 768px) {
    .label {
        font-size: 1.4rem;
    }

    textarea {
        font-size: 1.6rem;
    }
}