@import '../assets//styles/_variables.sass';

.files_wrap {
  width: 50%;
  display: flex;
  justify-content: left;
  align-items: center;
  padding: 16px 0px 0px;
  cursor: pointer;
  overflow: hidden;
}

.files_wrap a {
  text-decoration: none;
}



.files_text_and_icon {
  display: flex;
  justify-content: left;
  align-items: center;
  width: 100%;

  &>svg {
    min-width: 20px;

    path {
      fill: $iconBrand
    }
  }

  &>div {
    font-style: normal;
    font-weight: 500;
    font-size: 2rem;
    line-height: 24px;
    color: $textPrimary;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.files_text_and_icon svg {
  margin-right: 18px;
  width: 24px;
  height: 24px;
}

.data__text {
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: fit-content;
}

.link {
  width: 99%;
  display: block;
}

@media (max-width: 768px) {

  .files_text_and_icon {

    &>div {
      font-size: 1.6rem;
    }
  }

  .files_wrap {
    padding-top: 12px;
    width: 100%;
  }

}

.icon {
  width: 40px;
  height: 40px;
  min-width: 40px;
  background-color: $buttonBrandSecondaryEnabled;
  margin-right: 12px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  &>svg {
    margin-right: 0;

    &>path {
      fill: $iconBrand
    }
  }
}

.size {
  font-size: 1.4rem;
  color: $textTertiary;
  line-height: 18px;
}

.title {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}