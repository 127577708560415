@import ../../../assets/styles/_variables
div.wrapper
    color: $textPrimary
    font-size: 1.6rem
    line-height: 21px
    white-space: pre-wrap
    overflow-wrap: anywhere

    .table__wrapper
        overflow: auto
    em
        font-style: italic
    strong
        font-weight: bold

    &.blockDesc
        font-size: 1.6rem
        line-height: 20px
        color: $textSecondary

    ol
        list-style: auto
    ul
        list-style: none
        li
            list-style-type: none
            margin-left: 10px
            &::before
                content: "•"
                margin-left: -0.5em
                margin-right: 0.1em
                display: inline-block
                align-items: center
                white-space: nowrap
                width: 1em
    a,a.editor-link
        color: rgb(33, 111, 219)
        text-decoration: none
    p,li,p.editor-paragraph,li.editor-listitem
        font-size: 1.6rem
        line-height: 21px
        color: $textPrimary
        margin-bottom: 8px
    li,li.editor-listitem
        margin: 8px 16px
    h1,h1.editor-heading-h1
        font-size: 4.8rem
        line-height: 56px
        color: $textPrimary
    h2,h2.editor-heading-h2
        font-size: 4rem
        line-height: 52px
        color: $textPrimary
    h3,h3.editor-heading-h3
        font-size: 3.2rem
        line-height: 42px
        color: $textPrimary
    h4,h4.editor-heading-h4
        font-size: 2.8rem
        line-height: 36px
        color: $textPrimary
    h5,h5.editor-heading-h5
        font-size: 2.4rem
        line-height: 31px
        color: $textPrimary
    h6,h6.editor-heading-h6
        font-size: 2rem
        line-height: 26px
        color: $textPrimary 
    img
        object-fit: contain

@media (max-width: 768px)
    .wrapper
        font-size: 1.4rem

    .wrapper.blockDesc
        font-size: 1.4rem
        text-align: left
