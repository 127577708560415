@import '../../assets/styles/_variables'
@import '../../assets/styles/_mixin'
@import '../../assets/styles/_styles'

.container
    width: 100% 
    border-radius: 24px
    padding: 24px
    background: $surfacePrimary
    box-shadow: $elevation4

.leafer
    width: 100%
    display: flex
    justify-content: space-between
    align-items: center
    border-bottom: 1px solid $borderBrandSecondary
    margin-bottom: 24px
    padding-bottom: 24px
    margin-bottom: 8px
    padding-bottom: 8px
    &__mode
        display: flex
        align-items: center
        gap: 5px
    &__button
        width: 20px
        height: 20px
        color: $textSecondary
        padding: 0
        border: none
        outline: none
        background: none
        cursor: pointer
        
        svg
            path 
                fill: $iconBrand
    &__title
        font-size: 2rem
        line-height: 2.4rem
        text-align: center
        color: $textPrimary
        text-transform: capitalize
        &:hover
            cursor: pointer
.arrow
    width: 100%
    min-width: 24px
    height: 24px
    &_left
        transform: rotate(90deg)
    &_right
        transform: rotate(-90deg)
.row
    display: grid
    grid-template-columns: repeat(7, minmax(0, 1fr))
    text-align: center
    margin-bottom: 8px
    position: relative
.col
    height: 40px
    width: 40px
    display: inline-flex
    padding: 8px 2px
    align-items: center
    justify-content: center
    margin: auto
.title
    font-style: normal
    font-weight: normal
    font-size: 1.4rem
    line-height: 1.6rem
    color: $textSecondary

.value
    font-family: $Poppins
    font-style: normal
    font-weight: normal
    font-size: 1.6rem
    // line-height: 2.4em
    color: $textPrimary
    display: flex
    width: 100%
    height: 100%
    border-radius: 12px
    border: 1px solid transparent

    &:hover
        cursor: pointer 
        background-color: $surfacePrimary
        border-radius: 12px 
        border: 1px solid $borderBrand
    &:empty:hover
        background: none
        cursor: auto
    &__content
        width: 100%
        height: 100%
        display: grid
        align-items: center

    &_hidden
        color: $textSecondary

        &:hover
            background: $surfaceTertiary
    &_now
        color: $textBrand
        // background-color: $surfaceBrand
        border-radius: 12px
    &_selected
        color: $textBrand
    &.col[data-choose="true"]
        color: $textTertiary
.change
    &__wrapper
        display: grid
        gap: 24px
    &__container
        display: grid
        grid-template-columns: 1fr 1fr
        gap: 8px
    &__button
        font-size: 1.6rem
        line-height: 20px
        color: $textPrimary
        padding: 8px
        border-radius: 12px
        display: inline-flex
        align-items: center
        justify-content: center
        text-transform: capitalize
        &_year
            font-size: 2rem
            line-height: 24px
            color: $textPrimary
            padding: 8px
            height: 42px
            border-radius: 12px
            display: inline-flex
            align-items: center
            justify-content: center
            &:hover
                cursor: pointer
                color: $textButton
                background-color: $surfaceBrand
        &:hover
            cursor: pointer
            color: $textButton
            background-color: $surfaceBrand
button.mode__cancel
    padding: 0
    margin: 0
    font-size: 2rem
    line-height: 24px
    width: fit-content
    background: none
    border: none
    color: $textBrand
    justify-self: end
    &:hover
        cursor: pointer

.event
    text-decoration: none
    border-top: 1px solid $borderBrandSecondary
    padding: 12px 0px
    font-size: 1.4rem
    display: flex
    justify-content: space-between
    align-items: center
    svg 
        width: 16px
        height: 16px 
        path 
            fill: $iconBrand

    &__title
        color: $textPrimary
        text-decoration: none

    &:last-child
        padding-bottom: 0px

    &__icon
        margin-right: 8px
        svg     
            width: 16px
            height: 16px

            path    
                fill: $iconSecondary
    &__title
        display: flex
        text-align: left
.weekdays
    text-transform: capitalize
    margin: auto

.value
    &_events
        background-color: $surfaceTertiary
        color: $textBrand !important
        &:hover
            background-color: $surfaceTertiary
    &_events:hover 

    &_events + .value_events
        border-radius: 0px 12px 12px 0px 
    &_events + .value_events > div:hover
        border-radius: 0px 12px 12px 0px
    &_selected
        background-color: $surfaceBrand !important
        color: $textButton !important
    &_nextDayEvents.value_events > div
         border-top-right-radius: 0px
         border-bottom-right-radius: 0px  
    &_nextDayEvents.value_events > div:hover
         border-top-right-radius: 0px
         border-bottom-right-radius: 0px  


.value_nextDayEvents.value_events > div
   //border-radius: 0px 0px 0px 0px

.value_events + .calendar_value_events > div
    border-radius: 0px 12px 12px 0px

.row.title
    margin-bottom: 16px

.day 
    border-radius: 12px
    position: relative
    +r(768)
        position: unset
.modal
    position: absolute
    @include card
    background-color: $surfacePrimary
    z-index: 2
    width: 280px
    margin-left: 24px
    left: 100%
    transform: translateX(-75%)
    padding: 24px
    box-shadow: $elevation4
    color: $textPrimary
    border-radius: 24px !important
    +r(768)
        left: -40px
        transform: none
        margin: 0px 16px
        width: calc(100vw - 32px)


.events
    &__weekday
        font-size: 2rem
        line-height: 24px
        margin-bottom: 12px
        text-transform: capitalize
    &__day
        font-size: 3rem
        line-height: 36px
        margin-bottom: 4px
    &__month
        font-size: 1.6rem
        line-height: 20px
    &__list
        padding-top: 12px