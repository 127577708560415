@import '../assets//styles/_variables.sass'

.wrapper
    // background: $surfaceSecondary
    background: $surfaceSecondary
    justify-content: center
    min-height: 100vh

*
    &::-webkit-scrollbar
        width: 8px
        background: inherit

    &::-webkit-scrollbar-track
        background: none

    &::-webkit-scrollbar-track-piece
        background: none

    &::-webkit-scrollbar-thumb
        background: $surfaceTertiary
        border-radius: 5px
        width: 8px
.wrapper
    // background: $surfaceSecondary
    background: $surfaceSecondary
    justify-content: center
    min-height: 100vh

.container
    width: calc(100% - 312px)
    margin: 0 auto
    position: relative

.app
    min-height: calc(100vh - 186px)
    margin-bottom: 24px
    // margin-bottom: 60px TODO:

.content
    max-width: calc(100% - 6px)

@media (min-width: 769px) and (max-width: 1180px)
    .container
        width: 100%
        padding: 0px 48px

@media (max-width: 768px)
    .container
        width: 100%
        padding: 0px 16px
        overflow: hidden
        padding-bottom: 24px
    .app
        margin-bottom: 0px
        padding-top: 81px
