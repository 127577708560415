@import ../../assets/styles/_variables

.wrapper
    width: 100%

    textarea
        padding: 10px 16px
        resize: none
        font-size: 1.6rem
        line-height: 24px
        width: 100%
        background: transparent
        border: none
        border: 2px solid $borderBrandSecondary
        min-height: 48px
        height: 48px
        border-radius: 8px
        overflow: hidden
        &:active
            background: transparent
            border: 0px
            outline: none
        &:focus
            background: transparent
            outline: none

        &:hover
            border: 2px solid $borderBrand

    textarea::placeholder
        color: $textTertiary
