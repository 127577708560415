@import ../assets/styles/_variables

.preloader 
    position: fixed
    top: 0
    height: 100%
    width: 100%
    z-index: 3
.auth
    display: grid
    grid-template-columns: repeat(2,1fr)
    min-height: calc(100vh - 81px)

    &__loader    
        position: absolute
        width: 100%
        z-index: 2

.col
    display: flex
    align-items: center
    justify-content: center
.left
    background-color: $surfacePrimary
    border-right: 1px solid $borderBrandSecondary

.slider
    max-width: 450px

.right
    justify-content: flex-start
    padding-left: 108px

.slider
    &__text
        color: $textBrand
        font-size: 3.2rem
        line-height: 40px
        white-space: pre-wrap
        text-align: center
        margin: 0 auto
        margin-bottom: 40px

    &__img
        margin: auto
        margin-bottom: 40px
        max-width: 456px
        margin: 0 auto

    &__dots
        display: flex
        justify-content: center

        &>span
            width: 12px
            height: 12px
            background: $surfaceTertiary
            border-radius: 4px

            margin-right: 12px

            &[data-active="true"]
                background-color: $surfaceBrand

            &:last-child
                margin-right: 0

            &:hover
                cursor: pointer


@media (min-width: 769px) and (max-width: 1180px)
    .auth
        grid-template-columns: repeat(1, 1fr)
        padding: 66px 24px
    .left 
        display: none

    .right
        padding-left: 0px

@media (max-width: 768px)

    .auth
        grid-template-columns: repeat(1, 1fr)
        padding: 66px 16px
    .left 
        display: none

    .right
        padding-left: 0px