@import '../assets//styles/_variables.sass'

.faq
	&__title
		font-size: 3.2rem
		margin: 24px 0px
		color: $textPrimary
		text-transform: capitalize
	&__questions
		margin-bottom: 24px

		h2
			font-size: 32px
			line-height: 40px
			font-weight: 700 

	&__item
		cursor: pointer
		margin-bottom: 24px
		border-bottom: 1px solid $borderBrandSecondary

		&:last-child 
			border-bottom: 0px
			margin-bottom: -16px

	&__question
		display: flex
		justify-content: space-between

		h3
			font-size: 20px
			line-height: 24px
			margin-bottom: 24px
			font-weight: 600

	&__answer 
		margin-top: -8px
		margin-bottom: 16px
		& > div > p
			color: $textSecondary !important
		h1, h2, h3, h4, h5, h6
			color: $textSecondary !important
		p
			font-size: 16px
			line-height: 20px
			color: $textSecondary

	&__open
		svg
			path
				fill: $iconPrimary

 
	&__close
			svg
				path
					fill: $iconPrimary
	&__contacts
		p 
			color: $textPrimary
			font-size: 16px
			line-height: 24px 
		p.faq__contact
			color: $textBrand
			text-decoration: underline
			&:not(:last-child)
				margin-bottom: 16px 
		a 
			color: $textPrimary			
			font-size: 16px
			line-height: 24px
			text-decoration: underline
		a.faq__contact
			color: $textBrand
			margin-bottom: 16px
			text-decoration: none
			display: block

			&:last-child
				margin-bottom: 0px

		h2
			font-size: 28px
			margin-bottom: 16px
			color: $textPrimary
  

.card
	background: $surfacePrimary
	box-shadow: $elevation2
	border-radius: 24px
	padding: 24px
	margin-top: 24px
	color: $textPrimary

@media (min-width: 1181px)
	.faq__wrapper
		display: grid
		grid-template-columns: 2fr 1fr
		column-gap: 24px
	.card 
		height: max-content 

@media (min-width: 769px) and (max-width: 1180px)
	.faq
		margin-bottom: -24px
		padding-bottom: 24px

		&__wrapper
			margin-top: 24px

		&__title
			display: none
 
@media (max-width: 768px)
	.card
		margin-top: 16px
		padding: 16px
	.faq
		&__wrapper
			margin-top: 24px
		&__title
				display: none
		&__questions
			padding: 16px
			margin-top: 0px
			margin: 0px -16px
			border-radius: 0px
			padding-bottom: 1px

			h2
				font-size: 2.4rem
				line-height: 32px
				margin-bottom: 8px
		&__item
			padding-bottom: 8px
			margin-bottom: 8px
			&:not(:last-child)
				border-bottom: 1px solid $borderBrandSecondary

		&__question
			h3
				font-size: 1.6rem
				line-height: 20px
				margin-bottom: 8px
		&__answer 
			p
				font-size: 1.4rem
		&__answer > div 
			color: $textSecondary
		&__close
			display: flex
			align-items: center
		&__open
			display: flex
			align-items: center
		&__contacts
			h2
				font-size: 2.4rem
				color: $textPrimary
				margin-bottom: 12px
			p
				font-size: 1.6rem
				margin-bottom: 4px

			p.faq__contact
				color: $textBrand
				margin-bottom: 12px