@import ../../assets/styles/_variables

div.wrapper
  width: 100%
  box-sizing: border-box
  position: relative
  border-radius: 16px
  margin-bottom: 22px 
  input
    width: 100%
    outline: none
    padding: 8px 12px
    border: 1px solid $borderSecondary
    // box-shadow: 0 0 3px #EEEEF1
    border-radius: 12px
    background-color: $surfacePrimary
    box-sizing: border-box
    margin-bottom: 4px
    font-size: 2rem
    color: $textPrimary

    &:focus
    // border: none
    &:invalid
      box-shadow: none

    &:focus
      border: 1px solid $borderBrand

    &.error
      border: 1px solid $borderNegative

    &:hover
      border: 1px solid $borderInvert

    &::placeholder
      color: $textTertiary
  input::-ms-reveal,input::-ms-clear
    display: none
    -webkit-text-security: disc
    -moz-webkit-text-security: disc
    -moz-text-security: disc
  input:focus::-webkit-textfield-decoration-container
    visibility: hidden
    pointer-events: none

.textarea
  width: 100%
  outline: none
  padding: 13px 0
  border: 1px solid $borderSecondary
    // box-shadow: 0 0 3px #EEEEF1
  border-radius: 16px
  background-color: $surfacePrimary
  box-sizing: border-box
  margin-bottom: 5px
  min-height: 98px

  &:invalid
    box-shadow: none

  &:focus-within
    border: 1px solid $borderBrand

  &.error
    border: 1px solid red
  &:hover
    border: 1px solid $borderInvert
  textarea
    padding: 0 16px
    resize: none

.label
  padding: 0 8px 0 0
  background-color: $surfacePrimary
  font-style: normal
  font-size: 1.6rem
  line-height: 20px
  color: $textSecondary
  margin-bottom: 4px
  display: block

.password
  width: 100%

  input
    padding-right: 40px

  &__image
    width: 24px
    height: 24px
    position: absolute
    top: 34px
    right: 13px
    // transform: translateY(-50%)
    &:hover
      cursor: pointer

.message
  color: $textNegative
  display: block
  font-size: 14px
  line-height: 1rem
  position: relative
  left: 0px
  min-height: 1rem
  width: calc(100% - 24px)
  max-width: 398px //TODO:

.radio
  display: none

  &:checked
    & + .radio__label
  //TODO: some active action!!!
  &__wrapper
    display: block

  &__label
    &:hover
      cursor: pointer

.mask
  position: absolute
  top: 2px
  left: 0
  border: 2px solid transparent
  z-index: -1

@media (max-width: 768px)
  .label  
    font-size: 1.4rem

  .wrapper input
    font-size: 1.6rem
    line-height: 20px
  div.wrapper 
    margin-bottom: 12px 
  div.wrapper input
    padding: 10px 14px    
    font-size: 1.6rem

  .textarea
    border-radius: 10px