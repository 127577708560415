@import ../assets/styles/_variables

.container
    padding-top: 80px
    &__title
        font-size: 4.8rem
        line-height: 56px
        text-transform: capitalize
        color: $textPrimary
        margin-bottom: 8px
    &__subtext
        line-height: 20px
        font-size: 1.6rem
        color: $textTertiary
    &__header
        margin-bottom: 8px
.wrapper
    display: grid
    grid-template-columns: 1fr 1fr
    column-gap: 24px

.tabs

.tab
    font-size: 2rem
    line-height: 24px
    color: $textPrimary
    padding: 24px
    border-radius: 12px

    display: flex
    justify-content: space-between
    align-items: center

    &__info
        display: flex
        align-items: center
        white-space: no-wrap
        text-overflow: ellipsis

    &_active
        background-color: $surfacePrimary
        box-shadow: $elevation2
        .icon
            &>path
                fill: $iconBrand
            &__wrapper
                background-color: $surfaceTertiary
        .progress
            display: block
.icon
    &>path
        fill: $iconSecondary
    &__wrapper
        width: 48px
        height: 48px
        display: flex
        align-items: center
        justify-content: center
        background-color: $surfacePrimary
        border-radius: 50%
        margin-right: 16px

.progress
    display: none

.form
    padding: 25px
    border-radius: 24px
    box-shadow: $elevation2
    background-color: $surfacePrimary
    height: fit-content
    &__title
        font-size: 2rem
        line-height: 24px
        color: $textSecondary
        &_wrapper
            display: flex
            align-items: center
            padding-bottom: 24px
            margin-bottom: 24px
            border-bottom: 1px solid $borderBrandSecondary
        &_icon
            margin-right: 9px
            &>path
                fill: $iconSecondary
    &__button
        display: flex
        justify-content: flex-end

div.field
    &>svg
        top: 36px

.avatar
    margin: 0 auto
    margin-bottom: 16px
.back
    margin-right: 24px
    text-transform: capitalize

@media (min-width: 769px) and (max-width: 1180px)
    .wrapper 
        grid-template-columns: 1fr
    .tabs ul
        display: flex
        justify-content: center
    .container
        padding-bottom: 80px
        &__header
            text-align: center

    .tab
        padding: 16px 16px 24px
        &__text
            display: none
        &_active
            background-color: transparent
            box-shadow: none
            .progress
                display: none
    .form
        width: 66%
        margin: auto

@media (max-width: 768px)
    .wrapper 
        grid-template-columns: 1fr
        padding-bottom: 64px
    .tabs ul
        display: flex
        justify-content: center
    .container
        padding-top: 24px
        &__header
            text-align: center
        &__title
            font-size: 4rem
            line-height: 48px
    .tab
        padding: 16px 16px 24px
        &__text
            display: none
        &_active
            background-color: transparent
            box-shadow: none
            .progress
                display: none
    .icon
        &__wrapper
            margin-right: 0px
    .avatar
        width: 120px
        height: 120px
        font-size: 4.8rem

    .form
        padding: 16px
        &__title
            font-size: 1.6rem
            &_wrapper
                margin-bottom: 16px
                padding-bottom: 16px