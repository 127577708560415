@import ../../../assets/styles/_variables

.Table
    &__dropdown
        z-index: 5
        display: block
        position: absolute
        box-shadow: rgba(0,0,0,0.2) 0 2px 8px
        background-color: $surfacePrimary
        padding: 8px 0
        border-radius: 16px
        &_item
            width: 100%
            font-size: 2rem
            line-height: 2.4rem
            font-family: $GilroyMedium
            padding: 8px
            border: none
            background: none
            &:hover
                cursor: pointer
                background-color: $surfaceSecondary

            &_block
                border-bottom: 1px solid $borderBrandSecondary
                &:last-child
                    border: none
    &-cell__action-button_container
        position: absolute
        top: 0
        left: 0
        will-change: transform
button.Table-cell__action-button
    background: none
    display: flex
    justify-content: center
    align-items: center
    border: 0
    position: relative
    border-radius: 50%
    padding: 0
    margin: 0
    align-items: center
    width: 21px
    height: 21px
    svg
        width: 14px
        height: 14px
        margin-right: 0
    &:hover
        cursor: pointer
.PlaygroundEditorTheme__table
    border-collapse: collapse
    border-spacing: 0
    overflow-y: scroll
    table-layout: fixed
    width: max-content
    margin: 20px 0

    &Selected
        outline: 2px solid $borderBrand

    &Cell
        border: 1px solid $borderBrandSecondary
        min-width: 75px
        vertical-align: top
        text-align: start
        padding: 6px 8px
        position: relative
        cursor: default
        outline: none

    &CellSortedIndicator
        display: block
        opacity: 0.5
        position: absolute
        bottom: 0
        left: 0
        width: 100%
        height: 4px
        background-color: $surfaceInvert

    &CellResizer
        position: absolute
        right: -4px
        height: 100%
        width: 8px
        cursor: ew-resize
        z-index: 10
        top: 0

    &CellHeader
        background-color: $surfaceSecondary
        text-align: start

    &CellSelected
        background-color: #c9dbf0

    &CellPrimarySelected
        border: 2px solid rgb(60, 132, 244)
        display: block
        height: calc(100% - 2px)
        position: absolute
        width: calc(100% - 2px)
        left: -1px
        top: -1px
        z-index: 2

    &CellEditing
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.4)
        border-radius: 3px

    &AddColumns
        position: absolute
        top: 0
        width: 20px
        background-color: #eee
        height: 100%
        right: 0
        animation: table-controls 0.2s ease
        border: 0
        cursor: pointer

    &AddColumns:after
        // background-image: url(../images/icons/plus.svg)
        background-size: contain
        background-position: center
        background-repeat: no-repeat
        display: block
        content: " "
        position: absolute
        top: 0
        left: 0
        width: 100%
        height: 100%
        opacity: 0.4

    &AddColumns:hover
        background-color: #c9dbf0

    &AddRows
        position: absolute
        bottom: -25px
        width: calc(100% - 25px)
        background-color: #eee
        height: 20px
        left: 0
        animation: table-controls 0.2s ease
        border: 0
        cursor: pointer

    &AddRows:after
        // background-image: url(../images/icons/plus.svg)
        background-size: contain
        background-position: center
        background-repeat: no-repeat
        display: block
        content: " "
        position: absolute
        top: 0
        left: 0
        width: 100%
        height: 100%
        opacity: 0.4

    &AddRows:hover
        background-color: #c9dbf0

@keyframes table-controls
    0%
        opacity: 0

    100%
        opacity: 1

.PlaygroundEditorTheme__tableCellResizeRuler
    display: block
    position: absolute
    width: 1px
    background-color: rgb(60, 132, 244)
    height: 100%
    top: 0

.PlaygroundEditorTheme__tableCellActionButtonContainer
    display: block
    right: 5px
    top: 6px
    position: absolute
    z-index: 4
    width: 20px
    height: 20px

.PlaygroundEditorTheme__tableCellActionButton
    background-color: #eee
    display: block
    border: 0
    border-radius: 20px
    width: 20px
    height: 20px
    color: #222
    cursor: pointer

    &:hover
        background-color: #ddd

.TableCellResizer__resizer
    position: absolute

.TableNode__contentEditable
    min-height: 20px
    border: 0px
    resize: none
    cursor: text
    display: block
    position: relative
    tab-size: 1
    outline: 0px
    padding: 0
    user-select: text
    font-size: 15px
    white-space: pre-wrap
    word-break: break-word
    z-index: 3
.table-cell-action-button-container
    position: absolute
    top: 0
    left: 0
    will-change: transform

.table-cell-action-button
    background-color: green
    display: flex
    justify-content: center
    align-items: center
    border: 0
    position: relative
    border-radius: 15px
    color: #222
    display: inline-block
    background-color: rgb(228, 228, 228)
    cursor: pointer
iframe.Youtube_Node_Embeded
    max-width: 560px
    width: 100%
    height: 315px
div.typeahead-popover
    font-family: $Gilroy
    font-size: 1.4rem
    line-height: 16px
    ul
        box-shadow: $elevation2
        // border: 1px solid $borderBrandSecondary
        padding: 0
        border-radius: 8px
        &>li
            color: $textSecondary
            padding: 12px 20px
            &:hover
                cursor: pointer
                background-color: $surfaceSecondary
.editor__text
    &_subscript
        font-size: 0.8em
        vertical-align: sub !important
    &_superscript
        font-size: 0.8em
        vertical-align: super

.PlaygroundEditorTheme__layoutContainer
    display: grid
    gap: 10px
    margin: 10px 0

.PlaygroundEditorTheme__layoutItem
    border: 1px dashed #ddd
    padding: 8px 16px
.editor-quote
    margin: 0
    margin-left: 20px
    font-size: 15px
    color: rgb(101, 103, 107)
    border-left-color: rgb(206, 208, 212)
    border-left-width: 4px
    border-left-style: solid
    padding-left: 16px
.editor-text-strikethrough
    text-decoration: line-through
.editor-text-code
    background-color: rgb(240, 242, 245)
    padding: 1px 0.25rem
    font-family: Gilroy, Consolas, Monaco, monospace
    font-size: 94%
    cursor: default
    display: inline-block
    position: relative
    -webkit-user-select: none
    user-select: none

span.editor-image
    cursor: default
    display: inline-block
    position: relative
    -webkit-user-select: none
    user-select: none
