@import ../../../assets/styles/_variables
@import ../../../assets/styles/_mixin
.lesson  

    &__body
        display: grid
        grid-template-columns: 3fr 1fr
        column-gap: 24px
    &__item
        margin-bottom: 24px
        & > div > div:not(:last-child)
            margin-bottom: 20px
    &__title
        display: flex
        column-gap: 16px
    &__right > div > div:not(:last-child)
        margin-bottom: 24px
.doc
    &__header
        display: flex
        justify-content: space-between
        margin-bottom: 24px
        +r(1180)
            flex-direction: column
            gap: 16px
    &__buttons
        display: flex
        column-gap: 16px
        +r(1180)
            justify-content: space-between
.lesson
    padding-top: 54px
    +r(1180)
        padding-top: 136px
    +r(768)
        padding-top: 54px
    &__lines
        display: flex
        gap: 8px
        margin-top: 8px
    &__body
        margin-top: 24px
        display: grid
        grid-template-columns: 3fr 1fr
        column-gap: 24px
        +r(1180)
            grid-template-columns: 1fr
    &__left
        display: flex
        flex-direction: column
        gap: 24px
        & > div > div:not(:last-child)
            margin-bottom: 24px
    &__right
        +r(1180)
            display: none   
        & > div > div
            display: flex
            flex-direction: column
            gap: 16px
    &__title
        display: flex
        justify-content: space-between
        align-items: center
        gap: 16px
    &__data
        flex: auto
        display: flex
        flex-direction: column
        gap: 4px
.reviews
    &__line
        display: flex
        column-gap: 8px
        align-items: center

        &:not(:last-child)
            margin-bottom: 24px
 
    &__ratingTitle
        display: flex
        justify-content: space-between
    &__rating
        +r(1180)
            display: none
        +r(768)
            display: block