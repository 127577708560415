@import ../assets/styles/_variables
@import ../assets/styles/_mixin

.root
    display: inline-block
    position: fixed
    right: 40px
    bottom: 40px
    z-index: 100
    +r(1180)
        bottom: 80px

.button
    border-radius: 50%
    width: 64px
    height: 64px
    background-color: $buttonBrandEnabled
    border: none
    outline: none
    display: inline-block
    padding: 16px
    &:hover
        cursor: pointer
.cancel
    width: 24px
    height: 24px
.wrapper
    position: absolute
    bottom: 0
    right: 0
    width: 400px
    height: 720px
    background-color: $surfaceSecondary
    display: flex
    flex-direction: column
    justify-content: space-between
    border-radius: 20px
    box-shadow: $elevation4
    max-height: calc(100vh - 50px)
    z-index: 100

    +r(451)
        position: fixed
        width: 100%
        top: 0
        left: 0
        height: 100%
        right: 0
        max-height: none
        border-radius: 0
        bottom: 0
header.header
    font-size: 2rem
    line-height: 24px
    color: $textButton
    background-color: $surfaceBrand
    display: grid
    grid-template-columns: 24px 1fr 24px
    gap: 8px
    padding: 12px
    padding-left: 24px
    border-radius: 20px 20px 0 0
    +r(451)
        border-radius: 0
        padding: 12px 16px
    svg
        width: 24px
        height: 24px
        &>path
            fill: $iconInvert
.footer
    background-color: $surfacePrimary
    border-top: 1px solid $borderBrandSecondary
    padding: 12px
    display: grid
    gap: 12px
    border-radius: 0 0 20px 20px
    +r(451)
        border-radius: 0
        padding: 12px 16px
    &__action
        display: flex
        gap: 12px
        align-items: flex-end
.message
    &__button
        border: 2px solid $borderBrand
        border-radius: 8px
        width: 40px
        min-width: 40px
        height: 40px
        background-color: $surfacePrimary 
        display: inline-flex
        align-items: center
        justify-content: center
        position: relative
        top: -2px
        &:hover
            cursor: pointer
        &>svg>path
            fill: $iconBrand 
        &.active
            background-color: $surfaceBrand
            &>svg>path
                fill: $iconButton
    &__date
        color: $textSecondary
        font-size: 1.4rem
        line-height: 16px
        text-align: center
        margin-top: 24px
    &__content
        word-wrap: anywhere
div.input

    &>textarea
        min-height: 40px
        height: 40px
        font-size: 1.6rem
        line-height: 1.6rem
        max-height: 68px
        overflow: auto
.documents
    max-height: 160px
    overflow: auto
    display: grid
    gap: 16px
    &>li
        font-size: 1.4rem
        line-height: 16px
        &>a
            display: flex
            gap: 8px
            color: $textPrimary
            text-decoration: none
            &>svg
                min-width: 16px
                width: 16px
                height: 16px
                &:hover
                    cursor: pointer
                    text-decoration: underline
.main
    overflow: auto
    overscroll-behavior: contain
    padding-right: 6px
    display: flex
    flex-direction: column
    height: 100%
    padding: 0px 6px 1px 12px
    height: 100%

    &::-webkit-scrollbar
        width: 4px
        background: none

    &::-webkit-scrollbar-track
        background: none

    &::-webkit-scrollbar-track-piece
        background: none

    &::-webkit-scrollbar-thumb
        background: $surfaceTertiary
        border-radius: 5px
        width: 4px
    +r(451)
        padding: 12px 16px
.message
    max-width: 244px
    padding: 12px
    color: $textPrimary
    font-size: 1.4rem
    line-height: 16px
    background-color: $surfacePrimary
    border-radius: 12px 12px 12px 0px
    position: relative
    &>svg
        position: absolute
        left: -12px
        bottom: 0
        &>path
            fill: $iconInvert
    &_owner
        background-color: $surfaceTertiary
        border-radius: 12px 12px 0px 12px
        &>svg
            left: unset
            right: -12px
            &>path
                fill: $surfaceTertiary
    &__wrapper
        display: flex
        gap: 12px
        height: fit-content
        margin-top: 24px

        &_owner
            flex-direction: row-reverse

.avatar
    width: 32px
    min-width: 32px
    height: 32px
    border-radius: 50%
    background-color: $tertiaryBlueHover
    color: $tertiaryBlue
    font-size: 1.4rem
    line-height: 16px
    display: inline-flex
    align-items: center
    justify-content: center
    align-self: end
    &_owner
        background-color: $surfaceTertiary
        color: $textBrand
.load__container
    display: flex
    gap: 4px
    align-items: center
    &_lazy
        justify-content: center
        &>svg.load_icon
            width: 20px
            min-width: 20px
            height: 20px
svg.load_icon
    width: 16px
    min-width: 16px
    height: 16px
    transform-origin: 50% 50%
    animation: rotate .52s infinite
    &>path
        fill: $iconBrand

@keyframes rotate
    0%
        transform: rotate(0deg)
    14%
        transform: rotate(45deg)
    25%
        transform: rotate(90deg)
    38%
        transform: rotate(135deg)
    50%
        transform: rotate(180deg)
    64%
        transform: rotate(225deg)
    75%
        transform: rotate(270deg)
    89%
        transform: rotate(315deg)
    100%
        transform: rotate(360deg)

.document
    &__wrapper
        margin-top: 12px
        padding-top: 12px
        border-top: 1px solid $borderBrandSecondary
    &__link
        font-size: 1.2rem
        line-height: 1.4rem
        margin-bottom: 8px
        &:last-child
            margin-bottom: 0

        &>a, div
            text-decoration: none
            color: $textSecondary
            display: flex
            gap: 8px
            align-items: center
            cursor: pointer
            &>svg
                min-width: 16px
                width: 16px
                height: 16px
                align-self: flex-start
                &>path
                    fill: $iconSecondary
        &:hover
            &>a
                color: $textPrimary
                &>svg>path
                    fill: $iconPrimary
    &__list_title
        margin-bottom: 8px
        font-size: 1.2rem
        color: $textTertiary 
.media
    &__wrapper
        display: flex
        flex-wrap: wrap
        gap: 8px
        &>img,&>video
            width: 72px
            height: 72px
            border-radius: 12px
            background-color: $surfacePrimary
            object-fit: cover
            &:hover
                cursor: pointer
.overlay
    position: fixed
    top: 0
    left: 0
    bottom: 0
    right: 0
    background-color: rgba(0,0,0,0.64)
    z-index: 101
    display: flex
    align-items: center
    justify-content: center
.container
    max-width: 52%
    position: relative
    +r(1440)
        max-width: 72%
    +r(768)
        max-width: calc(100% - 32px)
    &>.slick-list
        overflow: hidden

    &>div
        width: 100%

div.slide
    // background-color: red
    overflow: hidden
    width: 100%
    padding-top: 16px
    display: grid
    grid-template-rows: minmax(70px, 1fr)
    height: 100%

    img,video
        margin: 0 auto
        align-self: center
        // max-height: 100%
        object-fit: contain
        width: 90vw
        aspect-ratio: 16/9
        +r(768)
            aspect-ratio: 4/3

    &__description

        font-size: 1.4rem
        color: $textPrimary
        display: -webkit-box
        -webkit-line-clamp: 2
        line-clamp: 2
        -webkit-box-orient: vertical
        overflow: hidden
        text-overflow: ellipsis
        &_wrapper
            display: flex
            padding: 16px
            align-items: center
            gap: 8px
            svg
                min-width: 20px
                align-self: start
.link
    margin-left: auto
    padding: 0
    border: none
    background: none
    align-self: start
    &:hover
        cursor: pointer
    &>svg
        &>path
            fill: $iconBrand
.close

    position: absolute
    right: -40px
    top: -40px
    +r(768)
        right: -20px
        top: -50px

.button
    &_slide
        background-color: #fff
        width: 40px
        height: 40px
        border-radius: 10px
        border: none
        display: inline-flex
        align-items: center
        justify-content: center
        padding: 0

        &>svg
            width: 20px
            height: 20px
            min-width: 20px
        &:hover
            cursor: pointer
.icon
    min-width: 24px
