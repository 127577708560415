@import ../assets/styles/_variables
.wrapper
    padding-top: 24px
.title
    font-size: 3rem
    color: $textPrimary
    line-height: 36px
    margin-bottom: 24px
.card
    background: $surfacePrimary
    box-shadow: $elevation2
    border-radius: 24px
    padding: 24px 0px
    height: fit-content
    &_select
        margin-bottom: 24px
        &:last-child
            margin-bottom: 0
    &__header
        height: 24px
        display: flex
        justify-content: space-between
    &__title
        color: $textSecondary
        font-size: 2rem
        text-transform: capitalize 
        &_header
            padding-bottom: 16px
            margin-bottom: 16px
            border-bottom: 1px solid $borderBrandSecondary
            display: flex
            align-items: center
            text-transform: lowercase
            color: $textPrimary
            &>svg
                margin-right: 8px
                path
                    fill: $iconPrimary
    &__content
        display: flex
        flex-direction: column
        height: fit-content
        transition: height 2s 
    &__about
        font-size: 1.6rem
        line-height: 20px
        color: $textPrimary
.arrow
    border: none
    min-width: 24px
    width: 24px
    height: 24px
    display: flex
    align-items: center
    justify-content: center
    background: none
    &:hover
        cursor: pointer

    &_up>svg
        transform: rotate(180deg)
        transition: transform .22s
    &>svg
        &>path
            fill: $iconSecondary

.document
    display: inline-flex
    text-decoration: none
    align-items: center
    margin-bottom: 16px
    &:last-child
        margin-bottom: 0
    &>svg
        margin-right: 16px
    &>span
        font-size: 2rem
        line-height: 24px
        color: $textPrimary
.row
    display: grid
    grid-template-columns: repeat(auto-fit, minmax(10em, 1fr))
    margin-bottom: 8px
    min-height: 28px
    border-bottom: 1px solid $borderBrandSecondary
    padding: 0px 24px
    padding-bottom: 8px
    &:nth-last-child(2)
        border: 0px
        margin-bottom: -16px
.cell
    font-size: 2rem
    line-height: 24px
    color: $textPrimary
    display: flex
    align-items: center
    max-width: calc( 100% - 10px)
    overflow: hidden
    white-space: nowrap
    text-overflow: ellipsis
    position: relative

    a,span,div
        color: inherit
        text-decoration: none
        overflow: hidden
        white-space: nowrap
        text-overflow: ellipsis
.avatar
    min-width: 40px
    width: 40px
    height: 40px
    border-radius: 50%
    background-color: $surfaceTertiary
    margin-right: 16px
    font-size: 1.6rem
    display: flex
    align-items: center
    justify-content: center

    object-fit: cover
div.avatar
    color: $textBrand

div.person_email
    font-size: 1.4rem
    color: $textTertiary
.grid
    display: grid
    column-gap: 24px
    grid-template-columns: 2fr 1fr 
    &__right
        padding: 24px
    &__fit
        display: block
.position_mobile
    display: none

@media (min-width: 769px) and (max-width: 1180px)
    .grid
        display: flex
        flex-direction: column
        border-radius: 0px

        &__left
            order: 2
        &__right
            order: 1
            margin-bottom: 16px
    .title
        display: none



@media (max-width: 768px)
    .title
        display: none

    .wrapper
        padding-top: 20px
        margin: 0px -16px
    .grid
        display: flex
        flex-direction: column
        border-radius: 0px

        &__left
            order: 2
        &__right
            order: 1
            margin-bottom: 16px
    .card 
        border-radius: 0px
        padding: 16px
        padding-bottom: 8px

        &__about
            font-size: 1.4rem
            padding-bottom: 8px
        &__title
            font-size: 1.4rem
            margin-bottom: 12px
            padding-bottom: 12px

            &_header
                font-size: 1.6rem  
        &__content
            padding-top: 6px
            padding-bottom: 8px
            overflow: scroll

    .document
        svg
            width: 20px
            height: 20px 
            margin-right: 12px
        & > span
            font-size: 1.4rem
            line-height: 16px            
    .position_desktop
 
    .position_mobile
        display: none
        font-size: 1.2rem
        color: $textTertiary !important

    .department 
        flex-direction: column
        align-items: start
        white-space: normal
        justify-content: center

        & > div
            max-width: 100%

    .cell
        font-size: 1.4rem
        line-height: 18px
    .avatar
        width: 32px
        height: 32px
        min-width: 32px
        margin-right: 8px
        font-size: 1.4rem

    .row
        margin-bottom: 14px
        width: 519px
        grid-template-columns: 1fr 1fr 1fr
    div.person_email
        font-size: 1.2rem
    .arrow 
        padding-bottom: 12px
        width: 28px