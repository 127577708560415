@import ../assets/styles/_variables

.load
    font-size: 6rem
    position: fixed
    top: calc(50% - 100px)
    left: calc(50% - 100px)
    transform: translate(-50%,-50%)
    z-index: 101
    width: 200px
    height: 200px
    animation: rotate 1s linear infinite 
.overlay
    position: fixed
    top: 0
    left: 0
    bottom: 0
    right: 0
    background-color: rgba(35,34,38,0.4)
    z-index: 100
    background-color: $surfaceSecondary
.login
    /*background-color: #E2E0FF*/
  
.ring 
    height: 24px
    width: 24px
    color: $textSecondary
    position: relative
    display: inline-block
    border: 2px solid
    border-radius: 50%
    border-top-color: transparent
    animation: rotate 1s linear infinite 
    margin: auto

    &__wrapper
        padding: 16px 0px
        text-align: center

@keyframes rotate
  0%
    transform: rotate(0)
  100% 
    transform: rotate(360deg)
.preloader
    &__mini
        animation: preloader-rotate 1s infinite linear
        width: 24px
        height: 24px
        path
            fill: $iconBrand !important

@keyframes preloader-rotate
    100%
        transform: rotate(360deg)