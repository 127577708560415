@import '../assets/styles/_variables.sass';

.time {
    width: 32px;
    height: 32px;
}

.circle {
    width: 32px;
    height: 32px;
    position: relative;
}

.progress_ring {
    width: 32px;
    height: 32px;
    position: relative;
    overflow: visible;
}

.progress_ring_circle {
    stroke-dasharray: 100;
    stroke-dashoffset: 100;
    transform-origin: center;
    transform: rotate(-90deg);
    stroke-width: 8px;
    width: 100%;
    height: 100%;
}

.progressBar_info {
    width: 80px;
    position: absolute;
    top: 30px;
    left: calc(50% - 25%);
    text-align: center;
    z-index: 20;
}

.progressScore {
    font-style: normal;
    font-weight: 300;
    font-size: 48px;
    line-height: 56px;
    color: $textSecondary;
}

.progressScore_input {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-left: 12px;
}

.progressScore_input_text {
    font-style: normal;
    font-weight: 500;
    font-size: 2rem;
    line-height: 24px;
    color: $textSecondary;
    margin-right: 8px;
}

.progressScore_input div input {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 8px 10px;

    width: 55px;
    height: 40px;

    background: $surfacePrimary;

    border: 2px solid $borderBrandSecondary;
    border-radius: 12px;

    font-style: normal;
    font-weight: 500;
    font-size: 2rem;
    line-height: 24px;
    color: $textPrimary;
}

.progressBar_title {
    font-style: normal;
    font-weight: 500;
    font-size: 2rem;
    line-height: 24px;
    color: $textSecondary;
    margin-bottom: 24px;
}

.dots {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 10;
    display: flex;
    justify-content: center;
}

.dots::before {
    content: '';
    position: absolute;
    top: 13px;
    width: 8px;
    height: 8px;
    background: $surfaceBrand;
    border-radius: 50%;
}

circle.progress_ring_circle_bg {
    stroke: rgba(0, 204, 102, 0.12);
    stroke-width: 8px;
}

@media (max-width: 768px) {
    .progress_ring {
        width: 24px;
        height: 24px;
    }

    .progress_ring_circle {
        cx: 12;
        cy: 12;
        r: 12;
        stroke-width: 5px;
        stroke-dasharray: 75;
    }
    circle.progress_ring_circle_bg {
        cx: 12;
        cy: 12;
        r: 12;
        stroke-width: 5px;
    }
    .time {
        width: 24px;
        height: 24px;
        margin-right: 8px;
    }
}
