@import '../../assets/styles/_variables.sass'
@import '../../assets/styles/_styles.sass'

    
.bots
    @include card
    text-align: center
    margin-bottom: 24px

    &__image
        font-size: 1.6rem
        color: $textSecondary
        margin-bottom: 24px

        & svg
            margin-bottom: 24px
.bot
    padding: 12px 24px
    font-size: 2rem
    text-decoration: none
    background: $surfaceTertiary
    color: $textInvert
    display: flex
    border-radius: 12px
    justify-content: center
    align-items: center 
    column-gap: 8px

    &:not(:last-child)
        margin-bottom: 8px
.telegram
    background:#2CA5E0

.viber
    background: #7B509C
.whatsapp
    background: #33D951

