@import '../../assets//styles/_variables.sass'

 
.item
  width: 24px
  height: 6px
  margin-right: 8px
  border: 2px solid $borderBrandSecondary
  border-radius: 4px
  margin-bottom: 16px
  background: $surfaceTertiary
  &__wrapper
    display: flex
    flex-wrap: wrap
.completed
  background: $surfaceBrand
  border: 2px solid $borderBrand
.container 
  grid-template-columns: 1fr 5fr
  align-items: center
  width: 100%
.text__title
  font-style: normal
  font-weight: 600
  font-size: 3rem
  line-height: 32px
  color: $textPrimary
  margin-bottom: 8px

.text 
  font-size: 2rem
.text__desktop
  font-style: normal
  font-weight: 500
  font-size: 2rem
  line-height: 24px
  color: $textPrimary
  margin-bottom: 24px

.text__mobile
  display: none
 
.button
  display: flex
  justify-content: left
  padding: 10px 22px
  background: $buttonBrandEnabled
  border-radius: 12px
  color: $textButton
  font-size: 2rem
  cursor: pointer
  display: flex
  align-items: center
  line-height: 24px

  &:hover
    background: $buttonBrandHovered

.quiz
  &__failed
    gap: 16px
    align-items: center
    display: flex
    justify-content: space-between
    margin-bottom: 24px
  
@media (max-width: 768px)
  .container 
    flex-direction: column
    margin: auto
  .text__title 
    font-size: 2.4rem
    margin-bottom: 12px
    line-height: 20px

  .text__desktop
    font-size: 1.6rem
    margin-bottom: 12px
    line-height: 18px
  .text__mobile
    display: block
    font-style: normal
    font-weight: 500
    font-size: 2rem
    line-height: 24px
    color: $textPrimary
    margin-bottom: 16px

  .wrapper 
    text-align: left
  .item
    width: 24px
    height: 6px
    margin-right: 8px
    border: 2px solid $borderBrandSecondary
    border-radius: 4px
    margin-bottom: 16px
    background: $surfaceTertiary
  .completed
    background: $surfaceBrand
    border: 2px solid $borderBrand