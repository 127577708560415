@import ../assets/styles/_variables

.catalog__title
    font-size: 3.2rem
    margin-top: 24px
    color: $textPrimary

.products
    width: 100%
    display: grid
    grid-template-columns: repeat(4,1fr)
    column-gap: 24px
    row-gap: 24px

@media (min-width: 769px) and (max-width: 1180px)
    .products
        grid-template-columns: repeat(3, 1fr)
        padding-bottom: 56px
    .catalog__title
        display: none
@media (max-width: 768px)
    .products
        grid-template-columns: repeat(1, 1fr)
        padding-bottom: 56px
    .catalog__title
        display: none