@import ../../assets/styles/_variables

.radio
    width: 24px
    height: 24px
    position: relative
    border-radius: 50%
    border: 2px solid $borderSecondary

    &__wrapper
        position: relative
        display: inline-flex
        input[type="radio"]
            display: none

            &:checked + label.radio
                &:before
                    content: ''
                    display: block
                    background-color: $surfaceBrand
                    width: 12px
                    height: 12px

                    position: absolute
                    top: 50%
                    left: 50%
                    transform: translate(-50%, -50%)
                    border-radius: 50%

@media (max-width: 768px)
   .radio
        width: 20px
        height: 20px 