@import '../assets//styles/_variables.sass';


.lessonsButtons {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.lessonButton {
  margin-top: 24px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  background: $surfaceSecondary;
  border-radius: 12px;
  cursor: pointer;
  font-style: normal;
  font-weight: 500;
  font-size: 2rem;
  line-height: 24px;
  color: $textBrand;

  svg {
    width: 21px;
    height: 21px;
    margin-right: 9px;
  }
}

.lessonButtonMargin {
  margin-left: 16px;
  margin-right: 16px;
}

.lwssonWrap {
  border-top: 1px solid $borderBrandSecondary;
  margin-top: 8px;
  padding-top: 12px;
}

.iconLesson {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  margin-right: 12px;

  svg {
    path {
      fill: $iconPrimary
    }
  }

}

.lessonWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 12px 36px;
  margin: 0px -24px;

  &:hover {
    background: $buttonNeutralTertiaryHovered;
  }
}

.lessonWrap a {
  text-decoration: none;
}

.lwssonWrap a {
  text-decoration: none;
}


a.notBought {
  pointer-events: none;
  color: $textPrimary;

  svg {
    path {
      fill: $iconPrimary
    }
  }

  .moduleTitle {
    color: $textPrimary
  }
}

.moduleTitle {

  font-size: 1.6rem;
  line-height: 20px;
  color: $textPrimary;

  &.no_active {
    color: $textTertiary
  }
}

.moduleSubTitle {
  font-style: normal;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 20px;
  color: $textTertiary;

  display: flex;
  justify-content: left;
  align-items: center;

  svg {
    margin-right: 4px;
  }
}

.lessonBlocked {
  pointer-events: none;
}

a.link {
  display: block;
}

.arrStructure_wrap_right {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.status {
  font-size: 1.2rem;
  line-height: 14px;
}

@media (max-width: 768px) {

  .arrStructure_wrap_right>svg {
    width: 20px;
    height: 20px;
  }

  .moduleTitle {
    font-size: 1.4rem;
  }

  .iconLesson {
    margin-right: 14px;

    svg {
      width: 20px;
      height: 20px;
    }
  }

  .lessonWrap {
    padding: 7px 0px;
  }

  .lwssonWrap {
    padding-top: 10px;
    margin-top: 6px;
  }
}