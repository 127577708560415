@import ../../../assets/styles/_variables
@import ../../../assets/styles/_mixin
.wrapper
    padding-top: 54px
    +r(1180)
        padding-top: 136px 
    +r(768)
        padding-top: 54px 
.survey   
    &__header
        & > div:first-child
            +r(1180)
                width: 100% !important
                height: 24px !important

    &__lines
        display: flex
        gap: 8px
        margin-top: 8px
        margin-bottom: 16px

        &:first-child > div 
            +r(1180)
                height: 8px !important
    &__left
        & > div
            display: flex
            flex-direction: column
            gap: 16px
        & > div > .survey__lines:last-child
            +r(768)
                flex-direction: column
                gap: 8px

                & > div
                    width: 100% !important
    &__data
        display: flex
        flex-direction: column
        gap: 8px
    &__lines
        display: flex
        justify-content: space-between 
        &_head
            display: flex
            margin-top: 8px
            justify-content: start
            gap: 8px
            margin-bottom: 20px
    &__body
        display: grid
        grid-template-columns: 2fr 1fr
        column-gap: 24px
        +r(1180)
            grid-template-columns: 1fr
            gap: 16px

    &__item
        margin-bottom: 24px
        & > div > div:not(:last-child)
            margin-bottom: 20px
    &__title
        display: flex
        column-gap: 8px
        align-items: center
    &__right
        +r(768)
            display: none
    &__right > div > div:not(:last-child)
        margin-bottom: 16px
    &__right > div > div:not(:first-child)
        display: flex
        flex-direction: column
        gap: 4px
.doc
    &__header
        display: flex
        justify-content: space-between
        margin-bottom: 24px
    &__buttons
        display: flex
        column-gap: 16px
