@import '../../assets/styles/_variables.sass'

.mobileHeader
	&__cancel
		width: 11px
		height: 11px

		svg 
			path 
				fill: $iconPrimary

	&__title
		display: flex
		justify-content: center
		padding: 16px 0px
		background: $surfacePrimary
		border-bottom: 1px solid $borderBrandSecondary
		margin: 0px -24px

		svg
			position: absolute
			left: 24px

		h3
			font-size: 1.6rem
			color: $textSecondary

	&__menu
		display: flex
		justify-content: space-between
		padding: 8px 0px
		align-items: center

	&__menuLeft
		svg
			width: 6px
			height: 12px
			margin: 0px 13px

			path
				fill: $iconSecondary

		& > span
			font-size: 1.6rem
			color: $textPrimary

	&__menuRight
		display: flex

		& > div
			width: 36px
			height: 36px
			border-radius: 10px
			display: flex
			justify-content: center
			align-items: center

	&__buttonTeacher
		margin-right: 8px
		background: $surfaceBrand

	&__buttonInfo
		border: 1.5px solid  $borderBrand

		svg
			path
				fill: $iconBrand

.modal__header
	display: flex
	justify-content: space-between
	padding: 16px
	font-size: 1.6rem
	color: $textSecondary
	border-bottom: 1px solid $borderBrandSecondary
	margin-bottom: 20px

.modal__inner
	background: $surfacePrimary

 
@media (min-width: 769px) and (max-width: 1180px)
	.modal
		height: 100%
		top: 0px
		transform: none
		position: unset
		margin-left: auto
		width: 420px
		background: $surfacePrimary

		&__header
			padding: 24px

	.mobileHeader
		&__menu 
			display: none

	.mobileHeader__tabletBreadcrumbs > div
		display: block
		margin: 0px -48px


	.mobileHeader
		&__menuRight

			& > div
				width: 48px
				height: 48px

				svg 
					width: 24px
					height: 24px

	.mobileHeader__buttonInfo
		width: auto !important
		color: $textBrand
		font-size: 2rem
		padding: 12px 24px
		border: 2px solid $borderBrand

@media (max-width: 768px)
	.mobileHeader
		&__menuRight
			display: none

		&__buttonInfo
			margin-left: 16px