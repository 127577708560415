@import '../assets//styles/_variables.sass'

.products
    width: 100%
    display: grid
    grid-template-columns: repeat(4,1fr)
    column-gap: 24px
    row-gap: 24px

    &__title 
        font-size: 3.2rem
        margin-top: 24px
        color: $textPrimary

.courseName_bradcrumb
    margin-bottom: 30px

    &_main
        font-style: normal
        font-weight: 700
        font-size: 3.2rem
        line-height: 37px

        color: #A8A8A8
        margin-right: 16px

    &_second
        font-style: normal
        font-weight: 700
        font-size: 3.2rem
        line-height: 37px

        color: #000000

.courseName_buttons
    width: 100%
    display: flex
    justify-content: left
    align-items: center
    margin-bottom: 32px

.courseName_button
    display: flex
    flex-direction: row
    justify-content: center
    align-items: center
    margin-right: 16px

    width: 68px
    height: 34px

    border: 1px solid #000000

    font-style: normal
    font-weight: 400
    font-size: 1.6rem
    line-height: 18px

    cursor: pointer

.white
    background: #FFFFFF
    color: #000000

.black
    background: #000000
    color: #FFFFFF

.courseName_title
    width: 100%
    text-align: left
    font-style: normal
    font-weight: 400
    font-size: 3.2rem
    line-height: 34px

    color: #000000
    margin-bottom: 32px

.line
    width: 100%
    display: flex
    justify-content: center

    & a
        text-decoration: none

.buttonTrashed
    width: 230px
    padding-top: 36px

.tabLine
    width: 100%
    display: flex
    justify-content: left
    align-items: center
    position: relative
    padding: 24px 0 0px
    margin-bottom: 24px
    border-bottom: 1px solid $borderBrandSecondary

.button
    display: flex
    flex-direction: row
    justify-content: center
    align-items: flex-start
    padding: 8px 16px

    position: absolute
    right: 0
    width: 114px
    height: 34px

    background: #000000

    font-family: 'Arial'
    font-style: normal
    font-weight: 400
    font-size: 1.6rem
    line-height: 18px

    color: #FFFFFF
    cursor: pointer
@media (min-width: 768px) and (max-width: 1180px)
    .products
        grid-template-columns: repeat(3, 1fr)
        padding-bottom: 56px

        &__title
            display: none

@media (max-width: 768px)
    .products
        grid-template-columns: repeat(1, 1fr)
        padding-bottom: 56px
        row-gap: 20px
        &__title
            display: none
    .line
        overflow: scroll
        margin: 0px -24px
        padding-left: 24px
        width: 100vw
        a
            font-size: 1.6rem
            white-space: nowrap

    .line::-webkit-scrollbar
        display: none

    .line
        -ms-overflow-style: none
        scrollbar-width: none
    .tabLine
        padding: 16px 0px 16px