// new vars
$surfaceBrand: var(--surfaceBrand)
$surfacePrimary: var(--surfacePrimary)
$surfaceSecondary: var(--surfaceSecondary)
$surfaceTertiary: var(--surfaceTertiary)
$surfaceContrast: var(--surfaceContrast)
$surfaceHightContrast: var(--surfaceHightContrast)
$surfaceInvert: var(--surfaceInvert)
$surfaceAi: var(--surfaceAi)
$surfacePositive: var(--surfacePositive)
$surfaceWarning: var(--surfaceWarning)
$surfaceLimit: var(--surfaceLimit)
$surfaceNegative: var(--surfaceNegative)
  
$textBrand: var(--textBrand)
$textPrimary: var(--textPrimary)
$textSecondary: var(--textSecondary)
$textTertiary: var(--textTertiary)
$textInvert: var(--textInvert)
$textButton: var(--textButton)
$textBadges: var(--textBadges)
$textAi: var(--textAi)
$textPositive: var(--textPositive)
$textWarning: var(--textWarning)
$textLimit: var(--textLimit)
$textNegative: var(--textNegative)

$borderBrand: var(--borderBrand)
$borderBrandSecondary: var(--borderBrandSecondary)
$borderBrandTertiary: var(--borderBrandTertiary)
$borderPrimary: var(--borderPrimary)
$borderSecondary: var(--borderSecondary)
$borderTertiary: var(--borderTertiary)
$borderInvert: var(--borderInvert)
$borderAi: var(--borderAi)
$borderPositive: var(--borderPositive)
$borderWarning: var(--borderWarning)
$borderLimit: var(--borderLimit)
$borderNegative: var(--borderNegative)

$iconBrand: var(--iconBrand)
$iconPrimary: var(--iconPrimary)
$iconSecondary: var(--iconSecondary)
$iconTertiary: var(--iconTertiary)
$iconInvert: var(--iconInvert)
$iconButton: var(--iconButton) 
$iconAi: var(--iconAi)
$iconPositive: var(--iconPositive)
$iconWarning: var(--iconWarning)
$iconLimit: var(--iconLimit)
$iconNegative: var(--iconNegative) 

//colors 
  
$white: #FFFFFF
$black: #000000

$skeletonColor: var(--skeletonColor)

$neutral500: #798086
$neutral0: $black 
$neutral100: mix($neutral500, black, 16%) 
$neutral200: mix($neutral500, black, 30%) 
$neutral300: mix($neutral500, black, 60%) 
$neutral400: mix($neutral500, black, 80%) 
$neutral600: mix($neutral500, white, 70%) 
$neutral700: mix($neutral500, white, 40%) 
$neutral800: mix($neutral500, white, 20%) 
$neutral900: mix($neutral500, white, 4%) 
$neutral1000: $white

$blue500: #17B4E5
$blue100: mix($blue500, black, 16%) 
$blue200: mix($blue500, black, 30%) 
$blue300: mix($blue500, black, 60%) 
$blue400: mix($blue500, black, 80%) 
$blue600: mix($blue500, white, 70%) 
$blue700: mix($blue500, white, 40%) 
$blue800: mix($blue500, white, 20%) 
$blue900: mix($blue500, white, 4%) 

$green500: #00CC66
$green100: mix($green500, black, 16%) 
$green200: mix($green500, black, 30%) 
$green300: mix($green500, black, 60%) 
$green400: mix($green500, black, 80%) 
$green600: mix($green500, white, 70%) 
$green700: mix($green500, white, 40%) 
$green800: mix($green500, white, 20%) 
$green900: mix($green500, white, 4%)  
 
$yellow500: #FFBB33
$yellow100: mix($yellow500, black, 16%) 
$yellow200: mix($yellow500, black, 30%) 
$yellow300: mix($yellow500, black, 60%) 
$yellow400: mix($yellow500, black, 80%) 
$yellow600: mix($yellow500, white, 70%) 
$yellow700: mix($yellow500, white, 40%) 
$yellow800: mix($yellow500, white, 20%) 
$yellow900: mix($yellow500, white, 4%) 

$orange500: #FF6600
$orange100: mix($orange500, black, 16%) 
$orange200: mix($orange500, black, 30%) 
$orange300: mix($orange500, black, 60%) 
$orange400: mix($orange500, black, 80%) 
$orange600: mix($orange500, white, 70%) 
$orange700: mix($orange500, white, 40%) 
$orange800: mix($orange500, white, 20%) 
$orange900: mix($orange500, white, 4%) 

$red500: #FF4E6B 
$red100: mix($red500, black, 16%) 
$red200: mix($red500, black, 30%) 
$red300: mix($red500, black, 60%) 
$red400: mix($red500, black, 80%) 
$red600: mix($red500, white, 70%) 
$red700: mix($red500, white, 40%) 
$red800: mix($red500, white, 20%) 
$red900: mix($red500, white, 4%) 

//buttons
 
$buttonBrandDisabledAll: var(--buttonBrandDisabledAll)
$buttonBrandEnabled: var(--buttonBrandEnabled)
$buttonBrandHovered: var(--buttonBrandHovered)
$buttonBrandFocused: var(--buttonBrandFocused)
$buttonBrandPressed: var(--buttonBrandPressed)
$buttonBrandSecondaryEnabled: var(--buttonBrandSecondaryEnabled)
$buttonBrandSecondaryHovered: var(--buttonBrandSecondaryHovered)
$buttonBrandSecondaryFocused: var(--buttonBrandSecondaryFocused)
$buttonBrandSecondaryPressed: var(--buttonBrandSecondaryPressed)

$buttonNeutralEnabled: var(--buttonNeutralEnabled)
$buttonNeutralHovered: var(--buttonNeutralHovered)
$buttonNeutralFocused: var(--buttonNeutralFocused)
$buttonNeutralPressed: var(--buttonNeutralPressed)
$buttonNeutralSecondaryEnabled: var(--buttonNeutralSecondaryEnabled)
$buttonNeutralSecondaryHovered: var(--buttonNeutralSecondaryHovered)
$buttonNeutralSecondaryFocused: var(--buttonNeutralSecondaryFocused)
$buttonNeutralSecondaryPressed: var(--buttonNeutralSecondaryPressed)
$buttonNeutralTertiaryEnabled: var(--buttonNeutralTertiaryEnabled)
$buttonNeutralTertiaryHovered: var(--buttonNeutralTertiaryHovered)
$buttonNeutralTertiaryFocused: var(--buttonNeutralTertiaryFocused)
$buttonNeutralTertiaryPressed: var(--buttonNeutralTertiaryPressed)

//color or mixin?
$buttonAiEnabled: $blue500
$buttonAiHovered: $blue600
$buttonAiFocused: $blue600
$buttonAiPressed: $blue700

$buttonPositiveEnabled: $green500
$buttonPositiveHovered: $green600
$buttonPositiveFocused: $green600
$buttonPositivePressed: $green700

$buttonWarningEnabled: $yellow500
$buttonWarningHovered: $yellow600
$buttonWarningFocused: $yellow600
$buttonWarningPressed: $yellow700

$buttonLimitEnabled: $orange500
$buttonLimitHovered: $orange600
$buttonLimitFocused: $orange600
$buttonLimitPressed: $orange700

$buttonNegativeEnabled: $red500
$buttonNegativeHovered: $red600
$buttonNegativeFocused: $red600
$buttonNegativePressed: $red700

//elevation color (shadows)
//$elevationColorNone: $white
$elevationColor1: var(--elevation1)
$elevationColor2: var(--elevation2)
$elevationColor3: var(--elevation3)
$elevationColor4: var(--elevation4)
$elevationColor5: var(--elevation5)

//elevation 
// add mixin?
//$elevation0: 0px 4px 4px 0px 
//$elevation0: 0px 4px 4px 0px 
 
$elevation1: 0px 0px 4px 0px $elevationColor3, 0px 4px 6px 0px $elevationColor1
$elevation2: 0px 0px 4px 0px $elevationColor3, 0px 4px 12px 0px $elevationColor2
$elevation3: 0px 6px 16px 0px $elevationColor3 //14
$elevation4: 0px 8px 24px 0px $elevationColor4  
$elevation5: 0px 12px 32px 0px $elevationColor5   


 
//$shadowColor: var(--shadowColor)
//$strongShadowColor: var(--strongShadowColor)
//$shadow: 0px 4px 16px $shadowColor
//$strongShadow: 0px 4px 16px $strongShadowColor
 
// OLD VARIABLES 

//Primary
//$primaryPurple: #6B4DFF
//$primaryPurpleHover: #8d77ff
//$primaryPurpleActive: #5943cb
//$primaryBlack: #232226

//$primaryColor: var(--primary_color)
//$primaryHoverColor: var(--primaryHoverColor)
//$primaryActiveColor: var(--primaryActiveColor)
//$primaryWhite: var(--bg_color)
//$primaryText: var(--text_color)
//Secondary
//$secondaryDarkGray: var(--secondaryDarkGray)
//$secondaryGray: var(--secondaryGray)
//$secondaryLightPurple: var(--secondaryLightPurple)
//$secondaryBackground: var(--secondaryBackground) 
//$primaryColor40percent: var(--primary_color40percent)



//Tertiary
$tertiaryGreen: #00CC66
$tertiaryGreenLight: #DCF4EC
$tertiaryGreenHover: #75df76
$tertiaryGreenActive: #41ab42

$tertiaryOrange: #FFBB33
$tertiaryOrangeHover: #fea478
$tertiaryOrangeActive: #ca7044

$tertiaryRed: #FF4E6B
$tertiaryRedHover: #fe7778
$tertiaryRedActive: #ca4344

$tertiaryBlue: #17B4E5
$tertiaryBlueHover: rgba(23, 180, 229, 0.16)

// FONT

$Poppins: 'Poppins', sans-serif
$PoppinsSemiBold: 'PoppinsSemiBold', sans-serif
$PoppinsLight: 'PoppinsLight', sans-serif
$PoppinsBold: 'PoppinsBold', sans-serif
$PoppinsMedium: 'PoppinsMedium', sans-serif

$GilroyBold: 'GilroyBold', sans-serif
$GilroyMedium: 'GilroyMedium', sans-serif
$Gilroy: 'Gilroy', sans-serif
$GilroySemiBold: 'GilroySemiBold', sans-serif
$GilroyLight: 'GilroyLight', sans-serif

$UbuntuBold: 'UbuntuBold', sans-serif
$UbuntuMedium: 'UbuntuMedium', sans-serif
$Ubuntu: 'Ubuntu', sans-serif
$UbuntuSemiBold: 'UbuntuSemiBold', sans-serif
$UbuntuLight: 'UbuntuLight', sans-serif

$RobotoBold: 'RobotoBold', sans-serif
$RobotoMedium: 'RobotoMedium', sans-serif
$Roboto: 'Roboto', sans-serif
$RobotoSemiBold: 'RobotoSemiBold', sans-serif
$RobotoLight: 'RobotoLight', sans-serif



:export
  maincolor: $textBrand
