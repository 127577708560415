@import ../assets/styles/_variables

.in_progress,.pending
    color: $textAi
    background: linear-gradient(0deg, rgba(107, 77, 255, 0.12), rgba(107, 77, 255, 0.12)), $surfacePrimary
.completed, .product_bought
    background: linear-gradient(0deg, rgba(0, 204, 102, 0.12), rgba(0, 204, 102, 0.12)), $surfacePrimary
    color: $tertiaryGreen

.wrapper
    border-radius: 8px
    padding: 4px 8px
    display: flex
    align-items: center
.returned, .failed
    color: $tertiaryRed
    background: rgba(255, 78, 107, 0.12)
.not_started
    background-color: $surfaceSecondary
    color: $textSecondary
.approved
    @extend .completed
