@import ../../assets/styles/_variables

.swiper
    width: 100%
    height: 100%
    border-radius: 20px
    display: flex
    flex-direction: column

    &-slide
        text-align: center
        font-size: 18px
        background: #fff

        display: flex
        justify-content: center
        align-items: center
        height: auto

.Slider__wrapper
    position: relative
    min-width: 50vw

    .next-arrow
        right: -20px
    .prew-arrow
        left: -20px
    .slide-button
        background-color: $surfacePrimary
        width: 40px
        height: 40px
        border-radius: 10px
        border: none
        display: inline-flex
        align-items: center
        justify-content: center
        padding: 0
        position: absolute
        top: 50%
        transform: translateY(-50%)
        z-index: 10
        box-shadow: $elevation2
        &>svg
            width: 20px
            height: 20px
            min-width: 20px
            &:hover
                cursor: pointer
    .slide-disabled
        display: none
