.button
  padding: 0
  margin: 0
  background: none
  border: none
  display: flex
  align-items: center
  justify-content: center

  &:active
    border: none

  &:hover
    cursor: pointer
