@import ../assets/styles/_variables
@import ../assets/styles/_mixin

.wrapper
    padding: 24px 0
.header
    display: flex
    justify-content: space-between
    margin-bottom: 24px
    align-items: center

    &__title
        font-size: 3.2rem 
        color: $textPrimary

.container
    display: grid
    column-gap: 24px
    grid-template-columns: 1fr 1fr

.section
    background: $surfacePrimary
    box-shadow: $elevation2
    border-radius: 24px
    padding: 24px
    height: fit-content
    margin-bottom: 24px

    & > div:last-child
        margin-bottom: 0px
        

.title
    padding-bottom: 24px
    border-bottom: 1px solid $borderBrandSecondary
    font-size: 2rem
    color: $textPrimary
    display: flex
    align-items: center
    margin-bottom: 24px
    svg
        margin-right: 10px
        path
            fill: $iconPrimary
.avatar
    width: 160px
    height: 160px
    min-width: 160px
    margin-bottom: 16px
    background-color: $surfaceTertiary
    color: $textBrand
    font-size: 6.4rem
    display: flex
    align-items: center
    justify-content: center
    border-radius: 50%
    text-transform: uppercase
    margin: 0 auto
    &__img
        width: 160px
        height: 160px
        min-width: 160px
        margin-bottom: 16px
        border-radius: 50%
        margin: 0 auto
        object-fit: cover

div.input
    margin-bottom: 26px
    &.textarea
        margin: 0
        &>div
            margin: 0 
    input
        &::placeholder
            color: $textTertiary
    &.input__edit
        &>div
            background-color: $surfaceSecondary
            height: 124px
        input
            background-color: $surfaceSecondary
        textarea
            background-color: $surfaceSecondary
            height: 100%
.select
    margin: 0

.card
    padding: 0 24px
    background-color: $surfacePrimary
    box-shadow: $elevation2
    border-radius: 24px
    margin-bottom: 24px

    &__header
        padding: 24px 0
        border-bottom: 1px solid $borderBrandSecondary
        display: flex
        align-items: center

        color: $textSecondary
        font-size: 2rem
        line-height: 24px
        svg
            margin-right: 10px
    &__main
        padding: 24px 0
    &__departmentWrapper
        padding-bottom: 0px
        padding-top: 0px
        & > div:last-child
            margin-bottom: 0px
        +r(768)
            & > div:last-child   
                margin-bottom: 16px       
        
    &__info 
        justify-content: space-between
        align-items: center
        margin-bottom: 24px
    &__label
        display: flex
        align-items: center 
        font-size: 1.4rem
        color: $textTertiary
        &_vertical
            margin-bottom: 8px

    &__value
        color: $textPrimary
        font-size: 2rem 

    svg
        path
            fill: $iconSecondary

    &__department
        margin-bottom: 24px

    &__departmentName
        color: $textPrimary
        font-size: 1.6rem

    &__departmentParent
        font-size: 1.4rem
        color: $textTertiary

.avatar
    display: flex
    justify-content: center

.position__block
    position: relative
    margin-bottom: 96px
.link
    font-size: 2rem
    line-height: 24px
    display: inline-flex
    text-transform: capitalize
    border-bottom: 2px solid transparent
    padding-bottom: 10px
    color: $textSecondary
    margin-right: 24px
    &_active
        color: $textSecondary
        border-bottom-color: $textBrand
        color: $textBrand
.radio
    &__field
        margin-top: 16px
        margin-bottom: 16px
    &__title
        font-size: 1.6rem
        color: $textSecondary
        margin-bottom: 8px
    &__label
        font-size: 1.6rem 
        display: flex
        align-items: center
        margin-left: 8px
        color: $textPrimary
    &__wrapper
        display: flex
        column-gap: 16px

@media (min-width: 769px) and (max-width: 1180px)

    .header
        justify-content: flex-end
        &__title
                display: none
    .container
        grid-template-areas: "a a" "b c"
        grid-template-columns: 1fr

        & > div:first-child
            grid-area: a
        & > div:nth-child(2)
            grid-area: b
        & > div:last-child
            grid-area: c 
        .card
            &__info
                margin-bottom: 0px
            &__departmentWrapper
                padding-top: 0px 
    .title
        margin-bottom: 24px
    .wrapper
        margin-bottom: -24px

@media (max-width: 768px)
    .header
        &__title
                display: none
    .wrapper
        padding-top: 16px
    .header
        margin-bottom: 16px
        justify-content: flex-end
    .title
        font-size: 1.6rem
        margin-bottom: 16px
        padding-bottom: 16px
    .section
        padding: 16px
        border-radius: 20px
        padding-bottom: 2px
    .card 
        &__departmentWrapper
            padding: 0px
        &__department
            margin-bottom: 16px
        &__departmentName
            font-size: 1.6rem
        &__departmentParent
            font-size: 1.2rem
        &__info
            margin-bottom: 16px
        &__label
            font-size: 1.6rem
        &__value
            font-size: 1.6rem
    .container
        grid-template-columns: 1fr
    .accountSettings_wrap

        &_block
            grid-template-columns: repeat(1,1fr)

    .cardsLine
        &__left
            flex-direction: column

    .avatar > label
        width: 80px
        height: 80px
        font-size: 32px
        margin-right: 0px
    label.avatar
        width: 120px
        height: 120px
        min-width: 120px
        font-size: 4.8rem
        margin-bottom: 16px

    .tabLine    
        overflow: scroll
        margin: 0px -24px 30px
        padding-left: 24px  

    .tabLine::-webkit-scrollbar
        display: none

    .tabLine
        -ms-overflow-style: none
        scrollbar-width: none

    .link 
        font-size: 1.6rem
        line-height: 20px

    .profile
        &__name
            font-size: 2.4rem
            margin-bottom: 8px
        &__info
            font-size: 1.2rem
            margin-bottom: 8px
        &__phone
            font-size: 1.2rem

    .cardsLine
        font-size: 1.2rem
        margin-bottom: 12px
 
    div.input.textarea
        margin-bottom: 16px
    div.input
        margin-bottom: 22px