@import ../assets/styles/_mixin.sass
@import ../assets/styles/_variables
.login 
    width: 100vw  
    background: $surfacePrimary 
    overflow: hidden 
 

.header 
    width: 100% 
    height: 80px 

    background: $surfacePrimary
    border-bottom: 1px solid $borderBrandSecondary
    display: flex 
    justify-content: space-between 
    align-items: center 
    &_left 
        margin-left: 72px 
     
    &_right 
        margin-right: 74px 
     
 

.main 
    width: 100vw 
    height: calc(100vh - 81px) 
    background: $surfacePrimary
    display: flex 
    justify-content: space-between 
    align-items: center 
 

.left_block 
    width: 50vw 
    height: 100%  
    display: flex 
    justify-content: center 
    align-items: center  
    background-color: $surfacePrimary
    border-right: 1px solid $borderBrandSecondary
    &_wrap 
        width: 416px 
        &_line 
            width: 100% 
            display: flex 
            justify-content: center 
            align-items: center 
         
     
 
.text 
    font-style: normal 
    font-weight: 700 
    font-size: 32px 
    line-height: 40px 
    color: $textBrand
    margin-bottom: 16px 
 
.subtext 
    font-style: normal 
    font-weight: 500 
    font-size: 16px 
    line-height: 20px 
    text-align: center 
    color: $textPrimary
    margin-bottom: 40px 
 

.right_block 
    width: 50vw 
    height: 100% 
    background: $surfaceSecondary
    display: flex 
    justify-content: center 
    align-items: center 
 

.loginForm 
    display: flex 
    flex-direction: column 
    align-items: flex-end 
    padding: 24px 

    width: 416px 

    background: $surfacePrimary
    box-shadow: $elevation2
    border-radius: 24px 
 
    +r(768)
        margin: 0px 16px

.loginForm_header, .loginForm_header_popup 
    width: 100% 
    font-style: normal 
    font-weight: 500 
    font-size: 20px 
    line-height: 24px 

    color: $textSecondary

    display: flex 
    justify-content: left 
    align-items: center 
    padding-bottom: 24px 
    margin-bottom: 24px 

    border-bottom: 1px solid $borderBrandSecondary

    svg 
        margin-right: 10px 
     
 
.loginForm_header_popup 
    justify-content: space-between 
 

.loginForm form 
    width: 100% 
 

.buttons 
    width: 100% 
    display: flex 
    justify-content: right 
    align-items: center 
    & > button
        color: $textBrand
        font-size: 1.6rem
 
.password_wrap 
    position: relative 
    width: 100% 
    .recover 
        position: absolute 
        top: -1px 
        right: 0 
        font-style: normal 
        font-weight: 500 
        font-size: 16px 
        line-height: 20px 

        color: $textBrand 
        cursor: pointer 
        text-decoration: none 
     
 
.popUpText 
    width: 100% 
    font-style: normal 
    font-weight: 500 
    font-size: 16px 
    line-height: 20px 
    color: $textSecondary
    margin-bottom: 10px 
    text-align: left
 
.form
    margin-top: 14px
    +r(768)
        margin-top: 6px

    & .buttons
        margin-top: 30px
        +r(768)
            margin-top: 0px
.forms 
    margin-top: 14px 
 

.popUpWrap 
    position: absolute 
    top: 0 
    left: 0 
    right: 0 
    bottom: 0 
    z-index: 1 
    background: rgba($color: #000000, $alpha: 0.5) 
    display: flex 
    justify-content: center 
    align-items: center 
 
.popUpTextDark 
    color: $textPrimary
 

.right 
    display: flex 
    justify-content: right 
    align-items: center 
 
div.modal
    transform: translateX(-50%)
    height: auto
    width: 416px
    animation: none
    top: unset
    +r(768)
        border-radius: 24px 24px 0px 0px
        max-width: none
        height: auto !important
        margin-top: auto
        width: 100%
        transform: none

.closeIcon
    cursor: pointer
 

@media (min-width: 769px) and (max-width: 1180px)
    .left_block
        display: none

    .right_block
        width: 100%

@media (max-width: 768px) 
    .left_block 
        display: none

    .right_block 
        width: 100%

    div.modal .loginForm
        border-radius: 24px 24px 0px 0px
        max-width: none
        height: auto !important
        margin: 0px
        margin-top: auto
        width: 100%
        transform: none
    
