@import ../../assets/styles/_variables

.button
  outline: none
  background-color: transparent
  font-size: 1.6rem
  line-height: 20px
  border: 2px solid transparent

  &:focus
    border-color: $borderInvert
    background: $buttonBrandFocused
  &:hover
    cursor: pointer

  &.primary
    display: flex
    justify-content: left
    padding: 10px 20px
    background: $buttonBrandEnabled
    border-radius: 10px 
    color: $textButton

    &:hover
      background: $buttonBrandHovered
      border-color: $buttonBrandHovered 


  &.secondary
    display: flex
    justify-content: left
    padding: 10px 20px
    color: $textBrand
    border-radius: 10px
    text-transform: capitalize
    border-width: 2px
    border-style: solid
    border-color: $borderBrand
    &:focus
      border-color: $borderInvert

    &:hover
      background: $buttonBrandSecondaryHovered

  &.tetriary
    font-style: normal
    border: none
     color: $textBrand
    text-align: left

    &:hover 
 
button.button.button__disabled
  background-color: $buttonBrandDisabledAll
  color: $textTertiary
  pointer-events: none

  &:hover
    border-color: transparent
    border-image: none
    background: $buttonBrandDisabledAll
    color: $textTertiary

@media (max-width: 768px)
    .button
        font-size: 1.6rem

        &.secondary
          justify-content: center
          padding: 8px 16px
          line-height: 20px

        &.primary
          justify-content: center
          padding: 8px 16px
          line-height: 20px
          align-items: center