@import ../../../assets/styles/_variables
@import ../../../assets/styles/_mixin

.line_120
    width: 16%
.line_160
    width: 72%
    +r(1350)
        width: 160px
.line_331
    width: 44%
.rect_331
    width: 44%
    height: 32px
.rect_600
    width: 80%
    height: 48px
.line_744
    width: 100%

.breadcrumbs
    border-top: 1px solid $borderBrandSecondary
    border-bottom: 1px solid $borderBrandSecondary
    padding: 8px 0
    &__wrapper
        max-width: 744px
        margin: 0 auto

    &__line
        width: 29%
    +r(1350)
        padding: 8px 16px
.header
    padding-top: 46px
    display: flex
    flex-direction: column
    justify-content: flex-end
    padding: 8px 32px 16px 32px
    &__wrapper
        display: none
        min-height: 100px
        background-color: $surfacePrimary
        +r(1180)
            display: block

    &__top
        display: flex
        justify-content: space-between
        width: 100%
        max-width: 744px
        align-items: center
        margin: 0 auto

.container
    position: fixed
    top: 112px
    left: 0
    right: 0
    bottom: 0
    z-index: 100
    background-color: $surfaceSecondary
    +r(1180)
        top: 0
        gap: 0
.wrapper
    width: calc(100% - 312px)
    margin: 0 auto
    display: grid
    grid-template-columns: 250px minmax(700px,1fr)
    grid-template-rows: minmax(calc(100vh - 186px),1fr)

    +r(1350)
        display: flex
        flex-direction: column
        gap: 24px
    +r(1180)
        width: 100%

.aside
    display: flex
    flex-direction: column
    gap: 24px
    border-right: 1px solid $borderBrandSecondary
    +r(1350)
        max-width: 744px
        margin: 0 auto
        width: 100%
        border-right: none
        padding: 0
        gap: 0 
        padding: 0px 16px

    &__item
        padding: 28px
        padding-left: 0
        display: flex
        justify-content: space-between
        align-items: center
        border-bottom: 1px solid $borderBrandSecondary

.main
    padding: 24px 0
    display: flex
    flex-direction: column
    gap: 24px
    max-width: 744px
    width: 100%
    margin: 0 auto
    &__header
        display: flex
        flex-direction: column
        gap: 16px
        &_list
            display: flex
            align-items: center
            gap: 8px
    +r(1350)
        padding: 0px 16px
.content
    display: flex
    flex-direction: column
    gap: 16px
    min-width: 100%
