@import ../../assets/styles/_variables
@import ../../assets/styles/_styles

.calendar
	margin-top: 24px
	margin-bottom: 24px


@media (min-width: 769px) and (max-width: 1180px)
	.calendar 
		margin-top: 0px