@import '../assets/styles/_variables.sass';
@import "../assets/styles/_mixin.sass";


.card_big {
  //box-shadow: $elevation2;
}

.card {
  width: 100%;
  display: flex;
  padding: 24px;

  background: $surfacePrimary;
  box-shadow: $elevation2;
  border-radius: 24px;
  margin-bottom: 24px;

  &__title {
    font-size: 1.6rem;
    line-height: 20px;
    color: $textSecondary;

    @include r(768) {
      font-size: 1.4rem;
      line-height: 18px;
    }
  }
}

.container {
  width: 100%;
}

.container p {
  font-size: 2rem !important;
}

.description {
  font-style: normal;
  font-size: 2rem;
  line-height: 24px;
  padding: 0px 0px 16px;
}

.wrapFiles {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap; 
  margin-top: -16px;
}

.wrapFiles_top {
  border-top: 1px solid $borderBrandSecondary;
  margin-top: 16px;
}

.quiz__start {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &>span {
    font-style: normal;
    font-size: 2rem;
    line-height: 24px;
    color: $textTertiary;
  }
}

@media (max-width: 768px) {
  .card {
    padding: 16px
  }

  .container p {
    font-size: 1.6rem !important;
  }

  .card_big>.card {
    border-radius: 0px;
    padding: 20px 24px;
    margin: 0px -24px;
    width: 100vw;
    margin-bottom: 16px
  }

  .wrapFiles {
    margin-top: 12px;
    flex-direction: column;
    align-items: start;
  }
}

div.attachments {
  width: 99%;

  &__wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;
    width: 100%;

    &.attachments__wrapper_task {}

    @include r(768) {
      display: flex;
      flex-direction: column;
    }

  }



  &__title {
    font-size: 1.6rem;
    color: $textPrimary;
    position: relative;

    &::before {
      content: "";
      background-color: $textPrimary;
      height: 1px;
      width: 0%;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      transition: width .22s;
    }

    &:hover {
      &::before {
        width: 100%;
      }
    }
  }

  &__container {
    border-top: 1px solid $borderBrandSecondary;
    padding-top: 16px;
  }
}