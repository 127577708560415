@import ../assets/styles/_variables
@import ../assets/styles/_mixin
 

.wrapper
    padding: 24px 0
    display: grid
    grid-template-columns: minmax(0, 1fr) 2fr
    column-gap: 24px
.col

.user
    &__wrapper
        box-shadow: $elevation4
        border-radius: 24px
        background-color: $surfaceBrand
        padding: 24px
        margin-bottom: 24px
        display: flex
        align-items: center
        column-gap: 24px
    &__avatar
        min-width: 80px
        width: 80px
        height: 80px
        display: flex
        align-items: center
        justify-content: center
        border-radius: 50%
        background-color: $surfaceTertiary
        object-fit: cover

        font-size: 2rem
        color: $textPrimary

    &__info
        font-size: 3rem
        line-height: 36px
        color: $textButton
        max-width: calc(100% - 80px - 48px)

        h3
            margin-bottom: 4px
            white-space: pre
            overflow: hidden
            text-overflow: ellipsis
        p
            font-size: 1.6rem
            line-height: 20px 
.link
    &__container
        display: grid
        grid-template-columns: repeat(2,minmax(0, 1fr))
        column-gap: 12px
        row-gap: 12px
    &__name
        &_icon>path
            fill: $iconBrand
    &__card
        padding: 24px
        background: $surfacePrimary
        box-shadow: $elevation2
        border-radius: 24px
        text-decoration: none
    &__name
        font-size: 1.6rem
        color: $textPrimary
        margin-bottom: 8px
        display: flex
        justify-content: space-between

        svg
            min-width: 20px
            width: 20px
            height: 20px
    &__count
        font-size: 3rem
        line-height: 36px
        color: $textPrimary

.surveys
    background: $surfacePrimary
    box-shadow: $elevation2
    border-radius: 24px
    padding: 0 24px
    margin-bottom: 24px

    &__header
        padding: 24px 0
        border-bottom: 1px solid $borderBrandSecondary
        display: flex
        align-items: center
        justify-content: space-between
        font-size: 2rem
        color: $textPrimary

        a
            color: $textBrand

.survey
    &__info
        display: flex
        gap: 16px
        align-items: center
        color: $textTertiary
        &_name
            font-size: 1.6rem
        &_container
            display: grid
            gap: 8px
    &__container
        padding: 24px 0px
        border-bottom: 1px solid $borderBrandSecondary
        display: flex
        align-items: center
        text-decoration: none

        &>svg
            margin-left: auto

        &:last-child
            border-bottom-color: transparent
    &__icon
        margin-right: 10px
        width: 20px
        height: 20px
        &>path
            fill: $iconTertiary
    &__time
        display: flex
        align-items: center
        font-size: 1.6rem
        color: $textTertiary
    &__name
        font-size: 2rem
        color: $textPrimary

.courses
    &__header
        margin-bottom: 16px
        display: flex
        align-items: center
        justify-content: space-between
        font-size: 2rem
        color: $textPrimary

        a 
            color: $textBrand

.course
    padding: 12px
    background: $surfacePrimary
    box-shadow: $elevation2
    border-radius: 16px

.default
    &__closed
        pointer-events: none
    &__type
        margin-bottom: 4px
        font-size: 1.2rem
        line-height: 14px
        text-transform: capitalize
        color: $textTertiary
    &__review
        margin-left: 4px
    &__wrapper
        margin-bottom: 24px
        display: grid
        grid-template-columns: 1fr 1fr
        column-gap: 24px
    &__card
        display: grid
        grid-template-columns: minmax(250px, 1fr) minmax(250px,2fr)
        column-gap: 12px
        background-color: $surfacePrimary
        box-shadow: $elevation2
        border-radius: 24px
        padding: 12px
        text-decoration: none
        margin-bottom: 12px
        position: relative
        &:hover
            .default__hero>img
                transform: scale(1.1)
    &__hero
        border-radius: 12px
        overflow: hidden
        &_wrapper
            display: flex
            align-items: center
            justify-content: center
            background-color: $surfaceTertiary
            border-radius: 12px
            aspect-ratio: 16/9

            svg
                path
                    fill: $iconBrand
        &_closed
            display: flex
            align-items: center
            justify-content: center
            background-color: var(--secondaryBackground)
            border-radius: 12px
            aspect-ratio: 16/9
            position: relative

            svg
                width: 40px
                height: 40px
                z-index: 2
                path
                    fill: $iconSecondary

            & > img
                opacity: 0.4
                position: absolute

        &>img
            width: 100%
            transition: transform .22s ease-in
            object-fit: cover
            aspect-ratio: 16/9

    &__title
        font-size: 1.6rem
        line-height: 20px
        color: $textPrimary
    &__info
        display: flex
        flex-direction: column

        &_closed
            opacity: 0.4
    &__social
        display: flex
        align-items: center
        justify-content: space-between
        span
            display: inline-flex
            align-items: center
            color: $textTertiary
            font-size: 1.6rem
            svg
                margin-right: 5px
                &>path
                    fill: $iconTertiary
    &__footer
        display: flex
        align-items: center
    &__lessons
        color: $textTertiary
        font-size: 1.6rem
        margin-right: 16px
        text-transform: lowercase
        white-space: nowrap
    &__progress
        position: absolute
        top: 0
        left: 0
        bottom: 0
        background-color: $tertiaryGreen
        border-radius: 4px
        &_wrapper
            margin-right: 16px
            background-color: $tertiaryGreenLight
            height: 8px
            border-radius: 4px
            position: relative
            width: 100%
.coach
    color: $textPrimary
    font-size: 2rem
    line-height: 24px
    white-space: nowrap
    text-overflow: ellipsis
    overflow: hidden
    &__block
        display: flex
        align-items: center
        margin-top: auto
        margin-bottom: 8px
        color: $textPrimary
        font-size: 2rem
        line-height: 24px

    &__avatars
        margin-right: 8px
        display: flex
        &:empty
            margin-right: 0
    &__avatar
        &_wrapper
            border-radius: 50%
            height: 34px
            width: 34px
            background-color: $surfacePrimary
            padding: 2px
            overflow: hidden
            position: relative
            &>img
                height: 32px
                width: 32px
                border-radius: 50%
                object-fit: cover
                border: 2px solid white
            &:not(&:first-child)
                margin-left: -10px

.keep_learning
    border: none
    background-color: $surfaceBrand
    width: 40px
    height: 40px
    min-width: 40px
    border-radius: 50%
    display: flex
    align-items: center
    justify-content: center
    &>svg>path
        fill: $iconInvert
    &:hover
        cursor: pointer

.footer__line
    display: flex
    padding-top: 12px
    border-top: 1px solid $borderBrandSecondary

    & > div
        display: flex
        margin-right: 16px
        align-items: center
        color: $textTertiary
        font-size: 1.4rem

        svg
            width: 16px
            height: 16px
            margin-right: 4px

            path
                fill: $iconTertiary
.status
    font-size: 1.6rem
    position: absolute
    top: 16px
    left: 16px

.speaker__avatar
    width: 33px
    height: 33px
    min-width: 33px
    display: flex
    align-items: center
    justify-content: center
    border-radius: 50%
    background-color: $surfaceTertiary
    object-fit: cover
    font-size: 2rem
    color: $textBrand
.tabArrow
    display: none
.inPath
    margin-bottom: 24px
@media(min-width: 1180px) and (max-width: 1320px)
    
    .link__name > div 
        white-space: nowrap
        text-overflow: ellipsis
        overflow: hidden

@media (min-width: 769px) and (max-width: 1180px)
    .tabArrow
        display: flex
        align-items: center
        font-size: 1.6rem
        column-gap: 8px
        margin-top: 8px

        & > span 
            color: $textBrand
    .wrapper
        grid-template-columns: 1fr
    .link__container
        margin-bottom: 24px
        grid-column: 1 / 3
        grid-template-columns: repeat(4, 1fr)
    .certificates
        order: 4
    .link__name svg
        display: none
    .data
        display: grid
        grid-template-columns: 1fr 1fr
        column-gap: 24px

@media (max-width: 768px)
    .tabArrow
        display: none
    .user
        &__info
            width: calc(100vw - 32px - 32px - 80px - 8px)

            h3
                overflow: hidden
                text-overflow: ellipsis
    .wrapper
        grid-template-columns: 1fr
    .default__card
        grid-template-columns: 1fr
        max-width: calc(100vw - 32px)
        display: block

    .speaker__avatar
        font-size: 1.6rem

    .user
        &__wrapper
            column-gap: 12px
            padding: 16px
            margin-bottom: 16px
        &__avatar
            min-width: 80px
            width: 80px
            height: 80px
            font-size: 3rem
        &__info
            h3
                font-size: 2.4rem
                line-height: 32px
            p
                font-size: 1.4rem
    .link
        &__container
            grid-template-columns: repeat(2, 1fr)
            row-gap: 16px
            column-gap: 16px
            margin-bottom: 16px 
        &__card
            padding: 16px
            border-radius: 20px
        &__name
            font-size: 1.4rem
            line-height: 18px
        &__count
            font-size: 2.4rem
            line-height: 20px
    .surveys
        padding: 0px 16px
        margin-bottom: 16px

        &__header
            font-size: 1.6rem
            line-height: 20px
            padding: 16px 0px
    .survey
        &__container
            padding: 16px 0px
        &__name
            font-size: 1.6rem
        &__icon
            width: 16px
            height: 16px
            margin-right: 8px
    .courses
        &__header
            font-size: 1.6rem
    .default
        &__type
            font-size: 1rem
        &__title
            font-size: 1.6rem
        &__lessons
            font-size: 1.4rem
    .coach
        font-size: 1.6rem
        &__block
            font-size: 1.6rem
            margin-bottom: 0px
            padding-bottom: 4px
    .inPath .default__title
        font-size: 2rem
    .inPath .coach
        font-size: 2rem  
    .inPath .footer__line
        border-top: none 

        & > div 
            flex-grow: 1
    .inPath
        & .default__type
            font-size: 1.4rem
            margin-top: 12px