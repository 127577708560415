@import ../../assets/styles/_variables
@import ../../assets/styles/_styles.sass
@import ../../assets/styles/_mixin
header.header
    display: block
    background: $surfacePrimary 
    border-bottom: 1px solid $borderBrandSecondary
.header
    &__container
        display: flex
        justify-content: space-between
        align-items: center
        height: 64px

    &__center
        display: flex
        justify-content: space-between

    &__logo
        min-width: 165px
        display: flex
        align-items: center
        text-decoration: none

        img
            height: 28px

        p 
            margin-left: 10px 
            color: $textPrimary
            font-size: 3rem
            letter-spacing: 1px
    &__left
        display: flex
        gap: 56px
    .header__logoText
        margin-left: 8px
        color: #000
        font-family: $GilroyMedium
        font-size: 2.8rem
        line-height: 28px

    &__link
        margin-right: 24px
        text-decoration: none
        position: relative
        top: 2px
        font-style: normal
        font-size: 1.6rem
        line-height: 24px
        display: flex
        align-items: center
        color: $textPrimary
        padding: 26px 0 

        &:hover
            cursor: pointer
            color: $textBrand

        &_active 
            color: $textBrand
        &_active:after
            content: ""
            display: block
            background: $surfaceBrand
            width: 100%
            position: absolute
            height: 2px
            border-radius: 4px
            bottom: 8px

        &:last-child 

    &__right
        display: flex
        align-items: center
        padding-right: 8px
        gap: 32px
 

        &_icon 
            display: flex

            button
                cursor: pointer

                svg
                    width: 20px
                    height: 20px
                    path 
                        fill: $iconPrimary

            button:hover
                svg
                    path
                        fill: $iconSecondary

            & > div:not(:last-child)
                margin-right: 35px
.header__auth .header__right
    gap: 12px
.avatar
    width: 48px
    height: 48px
    border-radius: 50%
    object-fit: cover
    &_big
        min-width: 80px
        min-height: 80px
        width: 80px
        height: 80px
        margin: 0 auto
        margin-bottom: 16px
    &__text
        color: $textBrand
        font-style: normal
        font-weight: 600
        font-size: 1.6rem
        line-height: 24px
        background: $surfaceTertiary
        width: 40px
        height: 40px
        display: flex
        align-items: center
        justify-content: center
        border-radius: 50%
        &_big
            font-size: 3.2rem
            line-height: 40px
            width: 80px
            height: 80px
            margin: 0 auto
            margin-bottom: 16px

.user
    &__wrapper
        position: relative
        cursor: pointer

    &__popower
        position: absolute
        top: 100%
        right: 0
        width: 280px

        padding-bottom: 24px
        background-color: $surfacePrimary
        box-shadow: $elevation4
        border-radius: 24px
        display: block
        z-index: 2
        animation: open 0.5s cubic-bezier(0.4, 0, 0.2, 1) forwards
        &_close
            animation: closed 0.5s cubic-bezier(0.4, 0, 0.2, 1) forwards

        &_header
            padding: 32px
            padding-bottom: 24px

    &__name
        font-weight: 500
        font-size: 2rem
        line-height: 24px
        color: $textPrimary
        margin-bottom: 8px

    &__email
        font-style: normal
        font-weight: 500
        font-size: 1.4rem
        line-height: 16px
        color: $textSecondary
.link
    text-decoration: none
    color: $textPrimary
    &__container

        &>li
            font-style: normal
            font-weight: 500
            font-size: 1.6rem
            line-height: 20px
            color: $textPrimary
            
            a
                padding: 16px 32px
                display: block
            p
                padding: 16px 32px
                display: block

            &:hover
                background-color: $surfaceSecondary
                //color: $buttonBrandHovered
                & > a
                    //color: $buttonBrandHovered
            &:last-child
                margin-bottom: 0
                padding: 16px 32px
                &:hover
                    cursor: pointer
@keyframes open
    0%
        // padding: 0
        opacity: 0

    100%
        opacity: 1
        // padding: 32px

@keyframes closed
    0%
        opacity: 1
        // padding: 32px

    100%
        opacity: 0
        // padding: 0

.headerMobile
    position: fixed
    /* top: calc(100vh - 66px) */
    top: unset
    bottom: 0px
    left: 0
    display: flex
    width: 100vw
    justify-content: space-between
    padding: 12px 18px
    background: $surfacePrimary
    z-index: 20
    border-top: 1px solid $borderBrandSecondary
    display: none

    & > a, & > div
        display: flex
        align-items: center
        flex-direction: column
        text-decoration: none
        color: $textSecondary

    & > a.headerMobile__active
        color: $textBrand

    &__item

    svg
        margin-bottom: 8px
        path
            fill: $iconSecondary

    &__active 
        color: $textBrand
        svg 

            path 
                fill: $iconBrand
    &__active:before
        display: block
        content: ""
        width: 24px
        height: 4px
        background: $surfaceBrand
        border-radius: 0px 0px 2px 2px
        position: absolute
        top: 0px

.user__popower_cancel, .user__popower_negate
    display: none

.bots
    display: none
    position: absolute
    left: -195px
    top: -12px
    background-color: $surfacePrimary
    box-shadow: $elevation4
    border-radius: 24px
    padding: 8px 0px

    &__container
        position: relative

        &_connect
            display: flex
            align-items: center
            justify-content: space-between

            & > svg
                margin-right: 12px

        & > p:hover
            color: $buttonBrandHovered
.bots__container:hover .bots
    display: block

.bot
    padding: 16px 32px
    font-size: 1.6rem
    text-decoration: none
    color: $textPrimary
    display: flex !important
    justify-content: center
    align-items: center
    column-gap: 8px

.telegram
    color: #2CA5E0 !important
    svg
        path
            fill: #2CA5E0

.viber
    color: #7B509C !important
    svg
        path
            fill: #7B509C
.whatsapp
    color: #33D951 !important
    svg
        path
            fill: #33D951

.themes 
    display: none
    position: absolute
    left: -172px
    top: -12px
    background-color: $surfacePrimary
    box-shadow: $elevation4
    border-radius: 24px
    padding: 20px 0px
    &__item
        position: relative
        padding: 16px 32px 16px 52px
        font-size: 1.6rem
        text-decoration: none
        color: $textPrimary
        display: flex !important
        justify-content: center
        align-items: center
        column-gap: 8px
        svg
            position: absolute
            left: 28px
        &:hover
            background: $surfaceSecondary

    &__container
        position: relative

        &_connect
            display: flex
            align-items: center
            justify-content: space-between

            & > svg
                margin-right: 12px

        & > p:hover
            color: $buttonBrandHovered
.bots__container:hover .themes
    display: block
.nav
    &__center
        display: flex
        justify-content: space-between
        flex-direction: column


    &__link
        margin-right: 24px
        text-decoration: none
        position: relative
        top: 2px
        font-style: normal
        font-size: 1.6rem
        line-height: 24px
        display: flex
        align-items: center
        color: $textPrimary
        padding: 16px 0 
        width: 100%
        display: flex
        justify-content: space-between
        &:hover
            cursor: pointer
            color: $textBrand

        &_active 
            color: $textBrand
        & span
            position: relative
            width: fit-content
        &_active span:after
            content: ""
            display: block
            background: $surfaceBrand
            width: 100%
            position: absolute
            height: 2px
            border-radius: 4px
            bottom: -4px

        &:last-child
            margin-right: 0
        svg 
            width: 10px
            height: 10px
            path
                fill: $iconPrimary
    &__submenu  
        gap: 20px
        flex-direction: column 
        z-index: 99999
        padding-left: 40px
        bottom: calc(-100% - 26px)
        left: calc(-50% + 16px)
        border-radius: 16px
    &__learning
        position: relative
        +r(768)
            width: calc(100vw - 24px)
    &:hover .header__submenu
        display: flex
    &__notifications
        position: relative
        border-top: 1px solid $borderBrandSecondary
        line-height: 20px
        padding-top: 24px
        width: calc(100% - 16px)
        & > div
            display: flex
    &__unread 
        color: $textBadges
        background: $surfaceWarning
        margin-left: 16px
        top: -9px
        left: 10px
        font-size: 1.2rem
        border-radius: 12px
        border: 2px solid $borderPrimary 
        padding: 4px
        height: 20px
        min-width: 20px
        display: flex
        align-items: center
        justify-content: center

.nav__submenu .nav__link
    margin-right: 0px 
    white-space: nowrap 

@media (min-width: 768px) and (max-width: 1180px)
    header.header
        display: none
    header.header__auth
        display: block
    .header
        &__link
            margin-right: 16px
    .headerMobile
        justify-content: space-around

    .avatar
        &__text
            width: 40px
            height: 40px
            font-size: 1.6rem

@media (max-width: 768px)
    .bots
        position: unset
        display: block
        box-shadow: none
        background: none
        border-bottom: 1px solid $borderBrandSecondary
        padding: 0px
        margin-bottom: 12px
        padding-bottom: 12px
        &__container
            width: max-content
            margin: auto
            &_connect
                justify-content: center

                & > svg
                    transform: rotate(90deg)
    li.bots__wrapper
        &:hover
            background-color: $surfacePrimary
    .bots__opened
        & > svg
            transform: rotate(270deg)
    .bot
        justify-content: start
    header.header
        display: none

    header.header__auth
        display: block
    .user
        &__name
            font-size: 2.4rem
            font-weight: 600
            line-height: 32px

        &__email
            font-size: 1.2rem

    .header

        padding: 16px

        &__container
            width: 100%
            overflow: hidden
            padding: 0px
            height: unset

        &__logo
            img
                width: 28px
                height: 28px
            p
                letter-spacing: 0px
                font-family: "GilroySemibold", sans-serif
        img.header__logoText
            width: 113px
            height: 22px
            margin-left: 8px

    .user__popower
        width: 100vw
        height: 100vh
        top: 0px
        text-align: center
        border-radius: 0px
        display: flex
        flex-direction: column
        overflow: scroll
        padding-bottom: 20px
        position: fixed
        z-index: 6

        &_header
            padding-bottom: 20px
            border-bottom: 1px solid $borderBrandSecondary
            margin-bottom: 10px
            margin: 0px 24px 10px
        &_cancel
            display: block
            border-bottom: 1px solid $borderBrandSecondary
            padding: 20px
            text-align: right

            svg
                width: 14px
                height: 14px

                path
                    fill: $iconPrimary

        &_negate
            display: flex
            margin-top: auto
            justify-content: center
            border-top: 1px solid $borderBrandSecondary
            padding: 20px
            align-items: center
            padding-bottom: 0px
            margin: auto 24px 0px

            svg
                width: 20px
                height: 20px

            div
                margin-left: 24px
                font-size: 1.6rem
                color: $textSecondary

    .avatar
        &__text
            width: 40px
            height: 40px
            font-size: 1.6rem

            &_big
                width: 80px
                height: 80px
                font-size: 3.2rem

    .link
        color: $textSecondary
        &__container
            &>li
                a
                    padding: 10px 32px
                    color: $textSecondary
            &>li:last-child
                padding: 10px 32px
                color: $textSecondary
    .themes
        position: unset
        box-shadow: none
        padding: 0px

.header
    &__submenu
        display: none
        position: absolute
        @include card
        gap: 20px
        flex-direction: column 
        z-index: 99999
        padding: 20px 32px
        bottom: calc(-100% - 26px)
        left: calc(-50% + 16px)
        border-radius: 16px
    &__learning
        position: relative
        &:hover .header__submenu
            display: flex

.header__submenu .header__link
    margin-right: 0px
    padding-top: 0px
    white-space: nowrap
    padding-bottom: 0px
 