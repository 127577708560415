@import '../../assets/styles/_variables.sass'

.wrapper
    position: fixed
    top: 10px
    right: 20px
    z-index: 100

.message
    width: 280px
    padding: 10px 31px 10px 16px
    text-align: left
    overflow: hidden
    margin-bottom: 15px
    animation: start 0.22s ease
    position: relative
    border-radius: 14px
    color: $textPrimary
    &__title
        font-size: 1.6rem
        line-height: 20px
        margin-bottom: 4px
    &__info
        font-size: 1.4rem
        line-height: 18px
ol.list
    box-sizing: border-box
    padding-left: 16px
li.message__info
    list-style: decimal
.info
    background-color: $surfacePrimary
    border: 1px solid #91d5ff

.danger
    background-color: $surfacePrimary
    border: 1px solid $tertiaryRed

.success
    background-color: $surfacePrimary
    border: 1px solid $tertiaryGreen

.remove
    position: absolute
    top: 50%
    right: 16px
    transform: translateY(-50%)

    svg>path
    fill: $iconSecondary
    width: 11px
    height: 11px

@keyframes start
    from
        transform: translate(0, -100%)

    to
        transform: translate(0, 0)
