@import ../../assets/styles/_variables
@import ../../assets/styles/_mixin

%border
    border: 2px solid $borderBrandSecondary

.search>button[type="button"]
    height: 48px
    padding: 0 16px
    display: inline-flex
    gap: 8px
    align-items: center
    overflow: hidden
    background-color: $surfacePrimary
    border: 2px solid $borderBrandSecondary
    border-radius: 12px

    font-size: 2rem
    color: $textTertiary
    min-width: 100%
    &:hover
        cursor: pointer
    +r(768)
        min-width: 48px
        padding: 0
        justify-content: center
        &>svg
            margin-right: 0
        span
            display: none
    +r(600)
        font-size: 1.6rem
        line-height: 20px
        &>svg
            min-width: 20px
            width: 20px
            height: 20px

div.modal
    width: 800px
    background: $surfacePrimary
    box-shadow: $elevation4
    border-radius: 24px
    padding: 24px
    max-height: fit-content
    overflow-y: auto
    height: initial
    +r(600)
        width: 100%
        height: auto
        top: -100px
        position: absolute
        top: 0
        left: 0
        right: 0
        height: 100%
        border-radius: 0
        padding: 24px 16px

    &>div
        display: grid
        gap: 24px
        +r(600)
            gap: 16px
    &__header
        display: flex
        align-items: center
        padding-bottom: 24px
        justify-content: space-between
        border-bottom: 1px solid $borderBrandSecondary
        +r(600)
            padding-bottom: 16px

        &>span
            color: $textSecondary
            font-size: 2rem
            line-height: 24px
            +r(600)
                font-size: 1.6rem
                line-height: 20px
                color: $textPrimary
        .cancel
            width: 14px
            height: 14px
    &__content
        display: flex
        gap: 16px
        +r(600)
            gap: 12px
.button__search
    height: 48px
    font-size: 2rem
    line-height: 24px
    color: $textButton
    padding: 12px 24px
    width: fit-content
    white-space: nowrap

    display: inline-flex
    align-items: center
    justify-content: center
    background-color: $surfaceBrand
    border: 1px solid $borderBrand
    border-radius: 12px
    +r(600)
        font-size: 1.6rem
        line-height: 20px
        padding: 8px 16px
    &>svg>path
        fill: $iconInvert
    &:hover
        cursor: pointer
    &>svg>circle
        stroke: $iconInvert
    &.button__search_cancel
        border-color: $tertiaryRed
        color: $tertiaryRed
        background: transparent
.input
    padding: 0 16px
    @extend %border
    border-radius: 12px
    background-color: $surfacePrimary
    font-size: 2rem
    color: $textPrimary
    height: 48px
    width: 100%
    +r(600)
        font-size: 1.6rem
        line-height: 20px
    &::placeholder
        color: $textTertiary
    &:focus
        @extend %border

    &__wrapper
        position: relative
    &__container
        width: 100%

.dropdown

    display: grid
    grid-template-rows: 1fr
    overflow: hidden
    transition: all .22s

ul.dropdown__container
    border-radius: 12px
    background-color: $surfacePrimary
    overflow: hidden
    display: grid
    grid-template-columns: 100%
    gap: 12px
    min-height: 0
    transition: padding .2s
    &>li>a
        display: flex
        color: $textPrimary
        gap: 8px
        font-size: 2rem
        line-height: 24px
        align-items: center
        text-decoration: none
        +r(600)
            font-size: 1.6rem
            line-height: 20px
        &>svg
            min-width: 24px
            width: 24px
            +r(600)
                min-width: 20px
                width: 20px

        &>span
            overflow: hidden
            white-space: nowrap
            text-overflow: ellipsis
.container
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    row-gap: 24px
    &__print
        display: flex
        flex-direction: column
        justify-content: center
        align-items: center
        row-gap: 24px
    &>svg>circle
        stroke: $iconBrand
    +r(600)
        margin-top: 172px
.sub
    font-size: 1.6rem
    color: $textSecondary
    white-space: pre
    line-height: 20px
    +r(600)
        font-size: 1.4rem
        line-height: 18px
        white-space: pre-wrap
.text
    display: grid
    gap: 8px
.title
    color: $textSecondary
    font-size: 3rem
.editor
    display: grid
    justify-content: start
    text-align: left
    width: 100%
.documents
    display: grid
    grid-template-columns: 1fr
    gap: 12px
    &>li
        a
            display: flex
            gap: 8px
            font-size: 1.4rem
            text-decoration: none
            color: $textPrimary

            &>svg
                width: 16px
                min-width: 16px
                height: 16px
            &>span
                display: -webkit-box
                -webkit-line-clamp: 2
                -webkit-box-orient: vertical
                overflow: hidden
                text-overflow: ellipsis
    &__container
        width: 100%
        border-top: 1px solid $borderBrandSecondary
        padding-top: 24px
        display: grid
        text-align: left
        +r(600)
            padding-top: 16px
.based
    font-size: 1.6rem
    color: $textSecondary
    margin-bottom: 12px

svg.load_icon
    transform-origin: 50% 50%
    animation: rotate .52s infinite
    &>path
        fill: $iconBrand
.load_icon__container
    font-size: 2rem
    line-height: 24px
    color: $textPrimary
    display: flex
    align-items: center
    justify-content: flex-start
    gap: 8px
    +r(600)
        font-size: 1.6rem
        line-height: 20px

@keyframes rotate
    0%
        transform: rotate(0deg)
    14%
        transform: rotate(45deg)
    25%
        transform: rotate(90deg)
    38%
        transform: rotate(135deg)
    50%
        transform: rotate(180deg)
    64%
        transform: rotate(225deg)
    75%
        transform: rotate(270deg)
    89%
        transform: rotate(315deg)
    100%
        transform: rotate(360deg)
