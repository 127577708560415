@import '../assets//styles/_variables.sass'

.form
    width: 100% 
    margin-bottom: 24px
    background: $surfacePrimary
    box-shadow: $elevation2
    border-radius: 24px

.cards_wrap
    width: 100%
    height: 100%
    overflow: auto
    position: relative
    display: flex
    justify-content: center
    padding-top: 24px

.card
    padding: 24px
    background: $surfacePrimary
    box-shadow: $elevation2
    border-radius: 24px

.moduleUpload
    display: flex
    justify-content: left
    flex-direction: column
    color: $textSecondary
    font-size: 12px
    margin-bottom: 24px

.dragAndDrop_text_info
    padding-top: 20px

.baseTabLine
    margin-top: 52px

.tabLines
    width: 100%
    display: flex
    justify-content: left
    align-items: center
    position: relative
    padding-top: 24px

div.modal
    width: 400px
    background: $surfacePrimary
    box-shadow: $elevation4
    border-radius: 24px
    padding: 24px
    height: initial

    &__header
        display: flex
        align-items: center
        padding-bottom: 24px
        justify-content: space-between
        border-bottom: 1px solid $borderBrandSecondary
        &>span
            color: $textSecondary
            font-size: 2rem
            line-height: 24px
        .cancel
            width: 14px
            height: 14px
.icon
    margin-top: 24px
.modal__content
    color: $textPrimary
    font-size: 2rem
    line-height: 24px
    padding: 24px 0
    text-align: left

.modal__buttons
    display: flex
    align-items: center
    justify-content: flex-end

    button
        font-size: 2rem
        line-height: 24px
        border: 1px solid transparent
        text-transform: capitalize
        background-color: transparent
        &:hover
            cursor: pointer
        &:first-child
            color: $textBrand
            margin-right: 24px

        &:last-child
            color: $textBrand
            width: 100px
            height: 48px
            background: #FF4E6B
            border-radius: 12px

a.link
    font-size: 2rem
    line-height: 24px
    border: 1px solid transparent
    text-decoration: none
    background-color: transparent
    display: inline-flex
    align-items: center

    &__course
        color: $textBrand
        margin-right: 24px

    &__next
        color: $textButton
        padding: 0 24px
        height: 48px
        background: $surfaceBrand
        border-radius: 12px

    &:last-child
        margin-right: 0
.textarea
    width: 100%
    outline: none
    padding: 10px 14px
    border: 2px solid $borderBrandSecondary
    border-radius: 12px
    background-color: $surfacePrimary
    box-sizing: border-box
    margin-bottom: 4px
    font-size: 2rem
    color: $textPrimary
    height: 196px
    margin-bottom: 36px
    height: 124px
    &:active
        background: transparent 
        outline: none
    &:focus
        background: transparent
        outline: none

    &:hover
        border: 2px solid $borderBrand

@media (max-width: 768px)
    div.modal
        height: 100%
        border-radius: 0px
        width: 100%
        height: auto
        margin-top: auto
        border-radius: 24px 24px 0px 0px

        & > div
            display: flex
            height: 100%
            flex-direction: column

        &__header
            padding-bottom: 16px

            &>span
                font-size: 1.6rem
                line-height: 20px

    .icon
        margin: auto
        margin-top: 32px
        margin-bottom: 0px
        width: 120px
        height: 120px

    .modal__buttons
        margin-top: auto
        justify-content: right

    a.link
        font-size: 1.6rem

    .card > div:first-child 
        margin-bottom: 30px

    .modal 
        &__content  
            font-size: 1.4rem