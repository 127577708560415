@import '../assets/styles/_variables.sass'

.actionButton
  padding: 10px 20px
  background: $buttonBrandEnabled
  border-radius: 12px
  color: $textButton
  text-decoration: none
  font-size: 1.6rem
  line-height: 20px
  border: 2px solid transparent

  &:hover
    background: $buttonBrandHovered
 

@media (max-width: 768px)
  .actionButton
    font-size: 1.6rem
    padding: 8px 16px
    line-height: 16px