@import ../../assets/styles/_variables
@import ../../assets/styles/_mixin

.wrapper
    padding: 24px 0
.card
    background: $surfacePrimary
    box-shadow: $elevation2
    border-radius: 24px
    padding: 24px
    height: fit-content
div.InputControl
    margin-bottom: 0
.progress
    height: 8px
    width: 100%
    border-radius: 4px
    background-color: $buttonBrandHovered
    display: inline-block

    &_active
        background-color: $surfaceBrand
    &_selected
        box-shadow: $elevation2
        &.progress_correct
            box-shadow: 0px 2px 6px $tertiaryGreen
        &.progress_incorrect
            box-shadow: 0px 2px 6px $tertiaryRed
    &_incorrect
        background-color: $tertiaryRed
    &_correct
        background-color: $tertiaryGreen

    &__wrapper
        margin-bottom: 16px
        display: grid
        grid-template-columns: repeat(auto-fit, minmax(10px,1fr))
        gap: 8px
.question
    font-size: 2rem
    line-height: 24px
    &__wrapper
        color: $textPrimary
        padding-bottom: 16px
        border-bottom: 1px solid $borderBrandSecondary
        margin-bottom: 16px
    &__type

        display: inline-flex
        align-items: center
        color: $textPrimary
        &>svg
            margin-right: 8px
        &>span
            font-size: 1.4rem
            line-height: 16px
            color: $textSecondary
    &__description
        font-size: 1.6rem
        margin-top: 8px
        color: $textSecondary
    &__types
        display: flex
        align-items: center
        gap: 24px
        font-size: 1.4rem
        line-height: 16px
        color: $textSecondary
        margin-bottom: 8px
.button
    &__group
        display: flex
        align-items: center
        +r(600)
            display: grid
            gap: 8px
        &>div
            margin-left: auto
            display: flex
            gap: 16px
            +r(600)
                display: grid
                width: 100%
                gap: 8px

    &__prev
        margin-right: 16px
        +r(600)
            margin-right: 0

.answers
    &__wrapper
        margin-bottom: 16px
        display: grid
        gap: 16px
        +r(576)
            gap: 12px
.text
    font-size: 1.6rem
    line-height: 20px
    color: $textSecondary
    &>b
        font-weight: 700
.choise
    display: grid
    grid-template-columns: 24px 1fr
    align-items: center
    width: 100%
    column-gap: 8px
    &__container
        display: grid
        align-items: center
        grid-template-columns: 1fr 24px
        column-gap: 8px
        &_other
            grid-template-columns: 1fr
            gap: 16px
    &>label
        border: 2px solid $borderSecondary
        border-radius: 12px
        min-width: 24px
    &:last-child
        margin-bottom: 0
    &:hover>label
        cursor: pointer
    &__wrapper
        display: flex
        flex-direction: column
        row-gap: 16px
    &__radio
        &>span
            display: none
div.checkbox
    margin-right: 0

.title
    font-size: 3.2rem
    line-height: 36px
    color: $textPrimary
    margin-bottom: 4px
.date

    display: inline-flex
    align-items: center
    &>svg
        margin-right: 4px
    &.date_less
        color: $tertiaryRed
        &>svg
            path
                fill: $tertiaryRed
.header
    margin-bottom: 16px
    &__sub
        display: flex
        align-items: center
        gap: 24px
        font-size: 1.6rem
        line-height: 20px
        color: $textTertiary
    &__anonymouse
        display: inline-flex
        align-items: center
        gap: 8px
.container
    display: grid
    grid-template-columns: 2fr 1fr
    column-gap: 24px

.card__header
    padding-bottom: 16px
    margin-bottom: 16px
    border-bottom: 1px solid $borderBrandSecondary
    display: flex
    align-items: center
    &>svg
        margin-right: 8px
    &>span
        font-size: 2rem
        color: $textSecondary
.about
    font-size: 1.6rem
    line-height: 20px
    color: $textPrimary

@media (min-width: 768px) and (max-width: 1180px)
    .container
        display: flex
        flex-direction: column
    .card
        margin-bottom: 24px
    .wrapper
        margin-bottom: -24px

@media (max-width: 768px)
    .container
        display: flex
        flex-direction: column
    .card
        order: 2
        border-radius: 20px
        margin-bottom: 16px

        &__header
            font-size: 1.6rem
            margin-bottom: 16px
            padding-bottom: 16px
            line-height: 18px
    .desc
        margin-bottom: 16px

        padding: 20px 24px
    .about
        font-size: 1.4rem
        line-height: 18px

    .wrapper
        padding: 20px 0px 0px
    .title
        font-size: 2.4rem
        line-height: 32px
    .date
        font-size: 1.2rem
        line-height: 14px

        svg
            width: 16px
            height: 16px

    .question
        font-size: 1.6rem
        line-height: 20px
        &__type > span
            font-size: 1.2rem
            line-height: 14px
        &__wrapper
            padding-bottom: 12px
            margin-bottom: 12px
    .text
        font-size: 1.4rem
        line-height: 18px
    .choise > label
        min-width: 20px
.range
    display: grid
    gap: 16px

    &__wrapper
        display: grid

        gap: 16px
        align-items: center
        position: relative
        +r(576)
            gap: 4px

    &__title
        font-size: 1.4rem
        line-height: 16px
        color: $textSecondary
        &:last-child
            text-align: right
        &_wrapper
            display: flex
            justify-content: space-between
            gap: 10px
    &__value
        font-size: 3rem
        line-height: 36px
        width: 30px
        color: $textTertiary
        &:hover
            cursor: pointer
        +r(576)
            font-size: 4rem
            line-height: 48px
            width: fit-content
            display: none
            &:first-child
                display: inline
            &:last-child
                display: inline
        &_wrapper
            display: flex
            justify-content: space-between
        &_active
            color: $textBrand
            +r(576)
                color: $textTertiary
        &_selected
            color: $textBrand
            +r(576)
                color: $textTertiary
        &_mobile
            display: none
            +r(576)
                font-size: 4rem
                line-height: 48px
                display: block
                position: absolute
                color: $textBrand
                top: 0
                left: 50%
                transform: translateX(-50%)
div.range__other
    margin-bottom: 0
.scale
    display: grid
    gap: 16px
.hint
    display: grid
    grid-template-columns: 24px 1fr
    gap: 8px
    padding-top: 16px
    border-top: 1px solid $borderBrandSecondary
    &>p
        color: $textPrimary
        font-size: 1.4rem
        line-height: 16px
