@import ../../../assets/styles/_variables
div.modal
    width: 400px
    background: $surfacePrimary
    box-shadow: $elevation4
    border-radius: 24px
    padding: 24px
    height: initial

    &__header
        display: flex
        align-items: center
        padding-bottom: 24px
        justify-content: space-between
        border-bottom: 1px solid $borderBrandSecondary
        &>span
            color: $textSecondary
            font-size: 2rem
            line-height: 24px
        .cancel
            width: 14px
            height: 14px
.modal__content
    color: $textPrimary
    font-size: 2rem
    line-height: 24px
    padding: 24px 0
    text-align: left

.modal__buttons
    display: flex
    align-items: center
    justify-content: flex-end

.button
    font-size: 2rem
    line-height: 24px
    border: 1px solid transparent
    text-transform: capitalize
    background-color: transparent
    &:hover
        cursor: pointer
    &:first-child
        color: $textBrand
        margin-right: 24px

    &:last-child
        color: $textButton
        min-width: 100px
        padding: 0 24px
        height: 48px
        background: $tertiaryRed
        border-radius: 12px
    &_confirm:last-child

        background-color: $surfaceBrand

@media (max-width: 768px)
    div.modal
        width: 100%
        height: auto
        margin-top: auto
        border-radius: 16px 16px 0px 0px

        & > div
            display: flex
            flex-direction: column
            height: 100%

        &__header
            padding-bottom: 16px

            & > span
                font-size: 1.6rem
                line-height: 20px
                color: $textPrimary

            svg
                width: 11px
                height: 11px

                path
                    fill: $iconPrimary
    .modal__content
        font-size: 1.4rem
        line-height: 18px
        padding: 16px 0px

    .modal__buttons
        margin-top: auto

    .button
        font-size: 1.6rem
        padding: 8px 16px
        line-height: 20px

        &:last-child
            height: auto
            padding: 8px 16px

        &:first-child
            margin-right: 16px
